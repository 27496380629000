import { addLocaleData } from 'react-intl';
import th from 'react-intl/locale-data/th';
import en from 'react-intl/locale-data/en';

import thMessages from './messages/th';
import enMessages from './messages/en';
import settings from '../settings';

const langsPack = {
  erp : {
    th : require('./erp/th'),
    en : require('./erp/en'),
  },
  saleorder : {
    th : require('./saleorder/th'),
    en : require('./saleorder/en'),
  },
  alldayallnight : {
    th : require('./alldayallnight/th'),
    en : require('./alldayallnight/en'),
  },
  thanachart : {
    th : require('./thanachart/th'),
    en : require('./thanachart/en'),
  },
  wasu : {
    th : require('./wasu/th'),
    en : require('./wasu/en'),
  },
  tec : {
    th : require('./tec/th'),
    en : require('./tec/en'),
  },
  smartfarm : {
    th : require('./smartfarm/th'),
    en : require('./smartfarm/en'),
  },
  fms : {
    th : require('./fms/th'),
    en : require('./fms/en'),
  },
  fmscust : {
    th : require('./fmscust/th'),
    en : require('./fmscust/en'),
  },
  survtrex : {
    th : require('./survtrex/th'),
    en : require('./survtrex/en'),
  }
}

const thLang = {
    messages: {...thMessages},
    locale: 'th-TH',
    data: th
};

const enLang = {
    messages: {...enMessages},
    locale: 'en-EN',
    data: en
};

let langName = settings.name
if(settings.name === "aux" || settings.name === "airvenger" || settings.name === "asp" ||
    settings.name === "dsa" || settings.name === "dsa-sandbox" || settings.name === "dsapos" || settings.name === "dsapos-sandbox" ||
   settings.name === "tkpos"){
  langName = "erp"
}else if(settings.name === "btportal"){
  langName = "survtrex"
}
thLang.messages = {...thLang.messages, ...langsPack[langName].th}
enLang.messages = {...enLang.messages, ...langsPack[langName].en}

const appLang = {
    th: thLang,
    en: enLang,
};

addLocaleData(appLang.th.data);
addLocaleData(appLang.en.data);

export const translate = (key) => {
  const languageTag = (localStorage.hasOwnProperty("lang") ? localStorage.getItem("lang") : "th")
  const translations = appLang[languageTag].messages
  return translations[key]
}

export const tlang = (obj) => {
  const languageTag = (localStorage.hasOwnProperty("lang") ? localStorage.getItem("lang") : "th")
  const translations = appLang[languageTag].messages
  return translations[obj.id]
}

export default appLang;
