import React from 'react';
import { injectIntl } from 'react-intl';
import { connect} from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Measure from 'react-measure';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import MaterialIcon from 'react-material-iconic-font'
import KTIcon from './KTIcon'

import settings from '../../settings';
import api from '../../api';
import {MagbizGeneral} from '../../utils/Magbiz'
import {actionPerform as actionPerformInfo} from '../../redux/Info'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  input: {
    display: 'flex',
    padding: 0
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: "0px 1px 2px 1px",
    borderRadius: 3,
    height:"18px!important"
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    width: '90%',
    lineHeight:1.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  placeholder: {
    position: 'absolute',
    left: 2
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `#fff!important`,
      borderWidth: '1px',
    }
  },
  cssFocused: {},
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#fff!important'
  },
  cssNoOutlinedInput: {
    '&$cssNoOutlinedFocused $cssNoOutlinedNotFocused': {
      borderRadius: 2,
      borderWidth: '0px',
      borderColor: "#80bdff",
      boxShadow: "0 0 0 2px rgba(0,123,255,.25)"
    }
  },
  cssNoOutlinedFocused: {},
  cssNoOutlinedNotFocused: {
    borderWidth: '0px',
    borderRadius: '0px',
    borderBottomWidth : '1px',
    borderColor: '#f2f2f2!important'
  },
  cssNoOutlinedNotFocusedNoLine: {
    borderWidth: '0px',
    borderRadius: '0px',
  },
  cssNoOutlinedDotField: {
    borderWidth: '0px',
    borderRadius: '0px',
    borderBottomWidth : '1px',
    borderColor: '#BDBDBD!important'
  },
});

const textFieldStyle = {
  container: {
    position:'relative'
  }
};

const textFieldNormalStyle = {
  input:{
    paddingTop:0,
    paddingLeft:12,
    paddingRight:0,
    paddingBottom:0
  },
  inputWithIcon: {
    padding:0,
    paddingLeft:40
  },
  icon:{
    position:'absolute',
    left:16,
    top:26,
    color:settings.theme.primary.main
  },
  label:{
    fontSize:'0.9rem'
  },
  inputBase:{
    fontSize:'0.9rem'
  }
};

const textFieldSmallStyle = {
  input:{
    paddingTop:0,
    paddingLeft:9,
    paddingRight:0,
    paddingBottom:0
  },
  inputWithIcon: {
    padding:0,
    paddingLeft:30
  },
  icon:{
    position:'absolute',
    left:10,
    top:13,
    color:settings.theme.primary.main
  },
  label:{
    fontSize:'0.8rem'
  },
  inputBase:{
    fontSize:'0.7rem'
  }
};

function NoOptionsMessage(props) {
  return (
    <Typography
      variant="caption"
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {(props.selectProps.async ? props.selectProps.tlang({id:"search_please_type"}) : props.selectProps.tlang({id:"search_not_found"}) )}
      {/*props.children*/}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  let fieldStyle = (props.selectProps.small ? textFieldSmallStyle : textFieldNormalStyle )
  let outlineStyle = {}
  if(props.selectProps.light){
    fieldStyle.icon = {...fieldStyle.icon, color: '#fff'}
    fieldStyle.label = {...fieldStyle.label, color: '#fff'}
    fieldStyle.inputBase = {...fieldStyle.inputBase, color: '#fff'}
    fieldStyle.input = {...fieldStyle.input, color: '#fff', backgroundColor: "transparent"}
    outlineStyle = {
      root: props.selectProps.classes.cssOutlinedInput,
      focused: props.selectProps.classes.cssFocused,
      notchedOutline: props.selectProps.classes.notchedOutline
    }
  }else{
    fieldStyle.input = {...fieldStyle.input, color: "rgba(0,0,0,0.87)", backgroundColor: "transparent"}
  }
  if(props.selectProps.no_outline){
    if(!props.selectProps.isDisabled){
      fieldStyle.input = {...fieldStyle.input, backgroundColor: (props.selectProps.mytheme ? settings.theme.mytheme.input : (props.selectProps.light ? "transparent" : "#fafafa") )  }
    }

    fieldStyle.input = {...fieldStyle.input, paddingRight:(props.selectProps.mytheme && !props.selectProps.isDisabled ? 5 : 0) }

    if(props.selectProps.blankNotAllowed){
      fieldStyle.input = {...fieldStyle.input, paddingTop: 1, paddingBottom: 1, paddingLeft: 5, minHeight:20.3, marginTop:0.5}
    }else{
      fieldStyle.input = {...fieldStyle.input, paddingTop: 1, paddingBottom: 1, paddingLeft: 5}
    }
    outlineStyle = {
      root: props.selectProps.classes.cssNoOutlinedInput,
      focused: props.selectProps.classes.cssNoOutlinedFocused,
      notchedOutline: (props.selectProps.fieldOnTable ? props.selectProps.classes.cssNoOutlinedNotFocusedNoLine : (props.selectProps.dotField ? props.selectProps.classes.cssNoOutlinedDotField : props.selectProps.classes.cssNoOutlinedNotFocused))
    }
  }
  return (
    <div style={textFieldStyle.container}>
      {props.selectProps.icon &&
      <div style={fieldStyle.icon}>
        <MaterialIcon type={props.selectProps.icon} small={Boolean(props.selectProps.small)} large={!Boolean(props.selectProps.small)} />
      </div>}
      <TextField
        fullWidth
        variant="outlined"
        InputProps={{
          inputComponent,
          style: fieldStyle.inputBase,
          inputProps: {
            style: (props.selectProps.icon ? fieldStyle.inputWithIcon : fieldStyle.input),
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps,
          },
          classes:outlineStyle
        }}
        InputLabelProps={{
          shrink: true,
          style: fieldStyle.label
        }}
        {...props.selectProps.textFieldProps}
      />
      {!props.selectProps.isDisabled && props.selectProps.no_outline && props.selectProps.mytheme &&
      <div style={{position:'absolute', right:6, top:2}}>
        <KTIcon vicon="triangle-down" vicontype="Octicons"  size={12} color={fieldStyle.input.color} />
      </div>}
    </div>
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 600 : 300,
        fontSize: (props.selectProps.small ? '0.7rem' : '0.8rem' ),
        color: (props.data.disabled ? "#BDBDBD" : "black"),
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: (props.selectProps.no_outline ? 5 : 10),
        paddingRight: (props.selectProps.no_outline ? 5 : 10),
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  let fieldStyle = (props.selectProps.small ? textFieldSmallStyle : textFieldNormalStyle )
  let left = props.selectProps.classes.placeholder.left
  if(!props.selectProps.icon && !props.selectProps.small){
    left = 14
  }else if(props.selectProps.icon && !props.selectProps.small){
    left = 42
  }else if(!props.selectProps.icon && props.selectProps.small){
    left = 11
  }else if(props.selectProps.icon && props.selectProps.small){
    left = 32
  }
  let style = {left:left, fontSize:fieldStyle.inputBase.fontSize}
  if(props.selectProps.light){
    style.color = "#f2f2f2"
  }
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      style={style}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  let fieldStyle = (props.selectProps.small ? textFieldSmallStyle : textFieldNormalStyle )

  let color = "rgba(0,0,0,0.87)"
  if(props.selectProps.light){
    color = "#fff"
  }
  if(props.selectProps.color){
    color = props.selectProps.color
  }
  return (
    <Typography align={props.selectProps.align ? props.selectProps.align : "left" } className={props.selectProps.classes.singleValue} {...props.innerProps}
      style={{...fieldStyle.inputBase, color:color }} >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  let fieldStyle = {
    fontSize:"0.8rem",
    height:30
  }
  if(props.selectProps.small){
    fieldStyle.fontSize = "0.6rem"
    fieldStyle.height = 22
  }
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      style={fieldStyle}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} style={{fontSize:fieldStyle.height - 8}} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const IndicatorsContainer = (props) => {
  return null;
};

const ClearIndicator = (props) => {
  return null;
};

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  ClearIndicator
};

class KTTextSelect extends React.Component {

  state = {
    object: null,
    inputMaxWidth: 1900
  };

  componentDidUpdate(prevProps, prevState) {
    if(this.props.suggestions){
      if(this.props.suggestions !== prevProps.suggestions || this.props.defaultValue !== prevProps.defaultValue){
        this.setState({object : this.getObjectValue(this.props.defaultValue)})
      }
    }
  }

  getObjectValue = (ov) => {
    if(this.props.isMulti){
      if(!ov) return null
      const dvalues = ov.split("|")
      return this.props.suggestions.filter(item => MagbizGeneral.includes(dvalues, [item.value+""]))
    }else{
      const objVal = this.props.suggestions.find(item => item.value+"" === ov+"")
      if(objVal && objVal.text){
        return {...objVal, label:objVal.text}
      }else{
        return (typeof objVal === "undefined" ? null : objVal)
      }
    }
  }

  handleChange = objVal => {
    let obj = objVal
    if(Array.isArray(objVal)){
      obj = {label:"", value:""}
      obj = objVal.reduce((olist, item) => {
        if(olist.value !== "") {
          olist.label += "|"
          olist.value += "|"
        }
        olist.label += item.label
        olist.value += item.value
        return olist
      }, obj)
    }
    if(obj){
      let event = {
        target:{
          name:this.props.name,
          value:obj.value,
          label:obj.label
        }
      }
      if(!this.props.blankNotAllowed || typeof obj.value !== "undefined"){
        this.setState({object : objVal});
        this.props.onChange(event)
      }
    }else{
      let event = {
        target:{
          name:this.props.name,
          value:null,
          label:null
        }
      }
      this.setState({object : null});
      this.props.onChange(event)
    }
  };

  _onResize = (contentRect) => {
    if(this.props.suggestions){
      const ovalue = this.getObjectValue(this.props.defaultValue)
      this.setState({object : ovalue, inputMaxWidth : contentRect.bounds.width});
    }
  };

  render() {
    const { classes , small} = this.props;
    const tlang = this.props.intl.formatMessage;

    const selectStyles = {
      dropdownIndicator: () => ({
        padding: (small ? 5 : 10)
      })
    };

    let thisComponents = components
    if(this.props.no_outline){
      thisComponents = {...thisComponents, IndicatorsContainer}
    }

    const ajaxOptions = this.props.app.ajaxOptions
    const ajax = this.props.ajax
    const actionPerformInfo = this.props.actionPerformInfo

    return (
      <div className={classes.root} style={{...this.props.style,
            maxWidth:this.state.inputMaxWidth,
            height:(this.props.no_outline && !Boolean(this.props.isMulti) ? 23 : "auto"),
            verticalAlign:"top",
            marginRight:(this.props.align === "right" && this.props.disabled ? -10 : 0)}}>
        <NoSsr>
          <Measure bounds onResize={this._onResize} >
            {({ measureRef }) => <div ref={measureRef}>
              {this.props.async &&
              <AsyncSelect
                classes={classes}
                styles={selectStyles}
                textFieldProps={{
                  label: this.props.label,
                  required:this.props.required,
                  type:this.props.type,
                  margin:(this.props.no_outline ? "none" : (this.props.small ? "dense" : "normal"))
                }}
                isDisabled={this.props.disabled}
                //options={this.props.suggestions.slice(0, 30)}
                isOptionDisabled={(option) => Boolean(option.disabled) }
                components={thisComponents}
                onChange={this.handleChange}
                name={this.props.name}
                value={this.state.object}
                isSearchable={true}
                blankNotAllowed={this.props.blankNotAllowed}
                isClearable={!this.props.blankNotAllowed}
                placeholder={this.props.placeholder}
                icon={this.props.icon}
                small={this.props.small}
                fieldOnTable={this.props.fieldOnTable}
                dotField={this.props.dotField}
                no_outline={this.props.no_outline}
                mytheme={this.props.mytheme}
                align={this.props.align}
                light={this.props.light}
                color={this.props.color}
                isMulti={this.props.isMulti}
                tlang={tlang}
                async={this.props.async}
                //defaultOptions
                loadOptions={(inputValue, callback) => {
                  const ajaxObj = ajaxOptions[ajax]
                  if(ajaxObj){
                    api(ajaxObj.action,{...ajaxObj.payload, [ajaxObj.label]:inputValue}).then((response) => {
                      if(!response.error){
                        actionPerformInfo("INFO_UPDATE", {fdata:ajax+"_ajax_list", info:response.info})
                        const suggestions = response.info.reduce((list, item) => {
                            list.push({label : item[ajaxObj.label] , value : item[ajaxObj.value]});
                          return list
                        }, [])
                        setTimeout(function(){callback(suggestions)}, 200)
                      }else{
                        callback([])
                      }
                    }).catch((error) => {
                      callback([])
                    })
                  }else if(this.props.suggestions){
                    callback(this.props.suggestions.filter((item) => {
                      const inputValues = inputValue.split(/\s+/)
                      let result = false
                      for(let k = 0;k < inputValues.length; ++k){
                        if(inputValues[k] !== ""){
                          if(item.label.toLowerCase().includes(inputValues[k].toLowerCase())) {
                            result = true
                          }else{
                            return false
                          }
                        }
                      }
                      return result
                    }).slice(0, 50))
                  }else{
                    callback([])
                  }
                }}
              />}
              {!Boolean(this.props.async) &&
              <Select
                classes={classes}
                styles={selectStyles}
                textFieldProps={{
                  label: this.props.label,
                  required:this.props.required,
                  type:this.props.type,
                  margin:(this.props.no_outline ? "none" : (this.props.small ? "dense" : "normal"))
                }}
                isDisabled={this.props.disabled}
                options={this.props.suggestions}
                isOptionDisabled={(option) => Boolean(option.disabled) }
                components={thisComponents}
                onChange={this.handleChange}
                name={this.props.name}
                value={this.state.object}
                isSearchable={true}
                blankNotAllowed={this.props.blankNotAllowed}
                isClearable={!this.props.blankNotAllowed}
                placeholder={this.props.placeholder}
                icon={this.props.icon}
                small={this.props.small}
                fieldOnTable={this.props.fieldOnTable}
                dotField={this.props.dotField}
                no_outline={this.props.no_outline}
                mytheme={this.props.mytheme}
                align={this.props.align}
                light={this.props.light}
                color={this.props.color}
                isMulti={this.props.isMulti}
                tlang={tlang}
                async={this.props.async}
              />}
            </div>}
          </Measure>
        </NoSsr>
      </div>
    );
  }
}

KTTextSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default connect(({app}) => {
	return {app};
}, {
  actionPerformInfo
})(injectIntl(withStyles(styles, { withTheme: true })(KTTextSelect)));
