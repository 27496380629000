const moduleName = "smartfarm"

export default {
  name : moduleName,
  api : {
    server : "https://apiservices2.bestgeosystem.com/",  //"http://192.168.112.146:8080/",  //
    app_module : moduleName,
  },
  title : "Smart Farm System",
  description : "BestGeo smart farm management system",
  logo : "assets/"+moduleName+"/logo.png",               // 128*128 Color Logo
  logo_head : "assets/"+moduleName+"/logo-head.png",      // 200*34 Color Logo
  company_logo_path : "assets/"+moduleName+"/org",      // 278*114, 139*57 Color Logo
  theme : {
    primary: {
      light: "rgba(211,47,47,0.8)",
      main: "rgba(211,47,47,0.9)",
      dark: "rgba(211,47,47,1)",
      contrastText: '#fff'
    },
    secondary: {
      light: "rgb(255,255,255,0.9)",
      main: "rgb(255,255,255,0.97)",
      dark: "rgb(255,255,255,1)",
      contrastText: '#424242'
    }
  },
  menu:{
    main_menu: {
  		title: "sidebar.section.main_menu",
      list: [
        {
  				"menu_title": "sidebar.dashboard",
  				"menu_icon": "view-dashboard",
  				"child_routes": [
            {"menu_title": "sidebar.dashboard_field2","path": "/"+moduleName+"/dashboard/field2", allowUserRole:["admin","it","exec1","exec2","gis","gcenter","xcenter","ccenter"]},
            {"menu_title": "sidebar.dashboard_field","path": "/"+moduleName+"/dashboard/field", allowUserRole:["admin","it","exec1","exec2","gis","gcenter","xcenter","ccenter","surveyorharvest","surveyor_admin"]},
            {"menu_title": "sidebar.dashboard_fleetsugarfarm","path": "/"+moduleName+"/dashboard/fleetsugarfarm",allowOrg:[818, 1094, 1104, 1065],},
            {"menu_title": "sidebar.dashboard_farmservice","path": "/"+moduleName+"/dashboard/farmservice", denyOrg:[1097, 1026]},
            {"menu_title": "sidebar.dashboard_farmservicegroup","path": "/"+moduleName+"/dashboard/farmservicegroup", denyOrg:[1097, 1026]},
          ]
  			},
        {"menu_title": "sidebar.farm_monitoring", "menu_icon": "local-activity", "path": "/"+moduleName+"/farm_monitoring", allowUserRole:["admin","it","exec1","exec2","gis","gcenter","xcenter","ccenter","surveyorharvest","surveyor_admin"]},
        {"menu_title": "sidebar.fleet_monitoring", "menu_icon": "car", "path": "/"+moduleName+"/fleet_monitoring"},
        {"menu_title": "sidebar.map", "menu_icon": "map", "path": "/"+moduleName+"/map", allowUserRole:["admin","it","exec1","exec2","gis","gcenter","xcenter","ccenter","surveyorharvest","surveyor_admin"]},
        {"menu_title": "sidebar.farmers", "menu_icon": "accounts-list", allowUserRole:["admin","it","exec1","exec2","gis","gcenter","surveyor_admin"], "child_routes": [
            {"menu_title": "sidebar.farmers_regist", "path": "/"+moduleName+"/farmers_regist"},
            {"menu_title": "sidebar.farmers", "path": "/"+moduleName+"/farmers"},
  			]},
        {"menu_title": "sidebar.contracts", "menu_icon": "accounts-list-alt", allowUserRole:["admin","it","exec1","gis","gcenter","surveyor_admin"], allowOrg:[1097, 1026], "child_routes": [
          {"menu_title": "sidebar.farmercontracts_regist", "path": "/"+moduleName+"/farmercontracts_regist"},
          {"menu_title": "sidebar.farmercontracts", "path": "/"+moduleName+"/farmercontracts"},
  			]},
        {
  				"menu_title": "sidebar.fields",
  				"menu_icon": "layers",
          allowUserRole:["admin","it","exec1","exec2","gis","gcenter","surveyorharvest","xcenter","surveyor_admin"],
  				"child_routes": [
  					{"menu_title": "sidebar.fields_status", "path": "/"+moduleName+"/fields/status"},
            {"menu_title": "sidebar.fields_plants", "path": "/"+moduleName+"/fields/plants"},
            {"menu_title": "sidebar.fields_age", "path": "/"+moduleName+"/fields/age"},
            {"menu_title": "sidebar.fields_staff", "path": "/"+moduleName+"/fields/staff"},
            {"menu_title": "sidebar.fields_harvestperiod", "path": "/"+moduleName+"/fields/harvestperiod"}
  				]
  			},
        {"menu_title": "sidebar.fieldharvest", "menu_icon": "power-input", allowOrg:[1097, 1026], allowUserRole:["admin","it","exec1","exec2","gis","gcenter","xcenter","ccenter","surveyorharvest","surveyor_admin"], "child_routes": [
          {"menu_title": "sidebar.harvestfields_status", "path": "/"+moduleName+"/harvestfields/status",},
          {"menu_title": "sidebar.fieldharvest_request", "path": "/"+moduleName+"/fieldharvest/request",},
          {"menu_title": "sidebar.fieldharvest_eval", "path": "/"+moduleName+"/fieldharvest/eval",},
        ]},
        {
  				"menu_title": "sidebar.farms",
  				"menu_icon": "collection-text",
          allowUserRole:["admin","it","exec1","exec2","gis","gcenter","surveyor_admin"],
  				"child_routes": [
            {"menu_title": "sidebar.farm_prepares", "path": "/"+moduleName+"/farm/prepares", allowOrg:[1097, 1026]},
  					{"menu_title": "sidebar.farm_plants", "path": "/"+moduleName+"/farm/plants", allowOrg:[1097, 1026]},
            {"menu_title": "sidebar.farm_evaluations", "path": "/"+moduleName+"/farm/evaluations", allowOrg:[1097, 1026]},
            {"menu_title": "sidebar.farm_growth", "path": "/"+moduleName+"/farm/growth", allowOrg:[1097, 1026]},
            {"menu_title": "sidebar.farm_verify", "path": "/"+moduleName+"/farm/verify", allowOrg:[1097, 1026]},
            {"menu_title": "sidebar.farm_brix", "path": "/"+moduleName+"/farm/brix", denyOrg:[1097, 1026]},
  				]
  			},
        {
  				"menu_title": "sidebar.plans", "menu_icon": "calendar-note",
          allowUserRole:["admin","it","exec1","exec2","gcenter","xcenter","ccenter","surveyorharvest","surveyor_admin"],
  				"child_routes": [
            //{"menu_title": "sidebar.plans_plow","path": "/"+moduleName+"/plans/plow",allowUserRole:["normal","kbs"],},
  					{"menu_title": "sidebar.plans_harvest","path": "/"+moduleName+"/plans/harvest"},
            {"menu_title": "sidebar.plans_transport", "path": "/"+moduleName+"/plans/transport"},
            {"menu_title": "sidebar.contact_truckerrequest", "path": "/"+moduleName+"/contact/truckerrequest"},
  				]
  			},
        {
  				"menu_title": "sidebar.works",
  				"menu_icon": "truck",
          allowUserRole:["admin","it","exec1","exec2","gcenter","xcenter","ccenter","surveyorharvest","surveyor_admin"],
  				"child_routes": [
            {"menu_title": "sidebar.works_plow", "path": "/"+moduleName+"/works/plow", allowOrg:[818, 1094, 920, 1026, 1097]},
  					{"menu_title": "sidebar.works_harvest", "path": "/"+moduleName+"/works/harvest"},
            {"menu_title": "sidebar.works_transportation", "path": "/"+moduleName+"/works/transportation"},
  				]
  			},
        {
  				"menu_title": "sidebar.documents",
  				"menu_icon": "money-box",
          allowUserRole:["admin","it","exec1","exec2","gcenter","surveyor_admin"], allowOrg:[818, 1094, 987, 1051, 1062],
  				"child_routes": [
            {"menu_title": "sidebar.documents_billgasoline", "path": "/"+moduleName+"/documents/billgasoline", allowOrg:[818, 1094]},
            {"menu_title": "sidebar.documents_deliverysugarcane", "path": "/"+moduleName+"/documents/deliverysugarcane", allowOrg:[987, 1051, 1062]},
  				]
  			},
        {"menu_title": "sidebar.report", "menu_icon": "file", "path": "/"+moduleName+"/report"},
        //{"menu_title": "sidebar.staffs", "menu_icon": "male", "path": "/"+moduleName+"/staffs", allowUserRole:["admin","it","exec1","exec2","surveyor_admin"],},
        {
  				"menu_title": "sidebar.contact", "menu_icon": "account", allowUserRole:["admin","it","exec1","exec2","xcenter"], 	"child_routes": [
            {"menu_title": "sidebar.contact_harvester", "path": "/"+moduleName+"/survtrex/contact/harvester"},
            {"menu_title": "sidebar.contact_trucker", "path": "/"+moduleName+"/survtrex/contact/trucker"},
            {"menu_title": "sidebar.contact_cablecardriver", "path": "/"+moduleName+"/survtrex/contact/cablecardriver"},
            {"menu_title": "sidebar.contact_vendor", "path": "/"+moduleName+"/survtrex/contact/vendor"},
  				]
  			},
        {"menu_title": "sidebar.settings", "menu_icon": "settings", allowUserRole:["admin"], "child_routes": [
          {"menu_title": "sidebar.settings_org", "path": "/"+moduleName+"/settings/org", allowUserRole:["admin"]},
          {"menu_title": "sidebar.settings_user", "path": "/"+moduleName+"/settings/user", allowUserRole:["admin"]},
          {"menu_title": "sidebar.settings_user_survtrex", "path": "/"+moduleName+"/survtrex/hr/staff", allowUserRole:["admin"]},
          {"menu_title": "sidebar.settings_notifytoken", "path": "/"+moduleName+"/settings/notifytoken"},
          {"menu_title": "sidebar.settings_notification", "path": "/"+moduleName+"/settings/notification"},
          {"menu_title": "sidebar.settings_project_vehicle_group", "path": "/"+moduleName+"/survtrex/settings/project_vehicle_group",},
          {"menu_title": "sidebar.settings_vehicle", "path": "/"+moduleName+"/survtrex/settings/vehicle",},
          {"menu_title": "sidebar.settings_fleet", "path": "/"+moduleName+"/settings/fleet", denyOrg:[1097, 1026]},
          {"menu_title": "sidebar.settings_fleet_s1", "path": "/"+moduleName+"/settings/fleet_s1", denyOrg:[1097, 1026]},
          {"menu_title": "sidebar.settings_fleet_group", "path": "/"+moduleName+"/settings/fleet_group", denyOrg:[1097, 1026]},
          {"menu_title": "sidebar.settings_fleet_category", "path": "/"+moduleName+"/settings/fleet_category", denyOrg:[1097, 1026]},
          {"menu_title": "sidebar.settings_fleet_division", "path": "/"+moduleName+"/settings/fleet_division", denyOrg:[1097, 1026]},
          {"menu_title": "sidebar.settings_fleet_zone", "path": "/"+moduleName+"/settings/fleet_zone", denyOrg:[1097, 1026]},
          {"menu_title": "sidebar.settings_fleet_channel", "path": "/"+moduleName+"/settings/fleet_channel", denyOrg:[1097, 1026]},
          //{"menu_title": "sidebar.settings_bank", "path": "/"+moduleName+"/settings/bank"},
  			]},
  		]
  	}
  },
  report_menu:{
    report: {
      list: [
        {"menu_title": "sidebar.report.field", "menu_icon": "map", allowUserRole:["admin","it","exec1","exec2","gis","gcenter","xcenter","ccenter","surveyor_admin"], "child_routes" : [
          {"menu_title": "sidebar.report.working_harvest_by_field", "path": "report/working_harvest_by_field"},
          {"menu_title": "sidebar.report.working_harvest_field_summary", "path": "report/working_harvest_field_summary"},
        ]},
        {"menu_title": "sidebar.report.harvester", "menu_icon": "nature", allowUserRole:["admin","it","exec1","exec2","gis","gcenter","xcenter","ccenter"], "child_routes" : [
          {"menu_title": "sidebar.report.working_harvest", "path": "report/working_harvest"},
          {"menu_title": "sidebar.report.trip_fieldlocation", "path": "report/trip_fieldlocation"},
          {"menu_title": "sidebar.report.working_harvest_summary", "path": "report/working_harvest_summary"},
          {"menu_title": "sidebar.report.plan_summaryharvest", "path": "report/plan_summaryharvest"},
        ]},
        {"menu_title": "sidebar.report.working", "menu_icon": "truck", allowUserRole:["admin","it","exec1","exec2","gis","gcenter","xcenter","ccenter"], "child_routes": [
          {"menu_title": "sidebar.report.working_transportation", "path": "report/working_transportation", "size":"A2landscape"},
          {"menu_title": "sidebar.report.working_checkpoint", "path": "report/working_checkpoint"},
          {"menu_title": "sidebar.report.working_summarytransportationzone", "path": "report/working_summarytransportationzone"},
          {"menu_title": "sidebar.report.working_summarytransportationdaily", "path": "report/working_summarytransportationdaily"},
          {"menu_title": "sidebar.report.working_transportationqueueweight", "path": "report/working_transportationqueueweight", "size":"A3landscape"},
          {"menu_title": "sidebar.report.plan_harvesttransport", "path": "report/plan_harvesttransport"},
        ]},
        {"menu_title": "sidebar.report.farmer", "menu_icon": "accounts-list", allowUserRole:["admin","it"], "child_routes" : [
          {"menu_title": "sidebar.report.farmer_field", "path": "report/farmer_field"},
        ]},
      ]
    }
  }
}
