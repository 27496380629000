const moduleName = "fms"

export default {
  name : moduleName,
  api : {
    server : "https://apiservices2.bestgeosystem.com/", //"http://192.168.122.146:8080/", //
    app_module : "fms",
  },
  title : "Fleet Management",
  description : "BestTech solution (besttechthailand.com)",
  logo : "assets/"+moduleName+"/logo.png",               // 128*128 Color Logo
  logo_head : "assets/"+moduleName+"/logo-head.png",      // 200*34 Color Logo
  theme : {
    primary: {
      light: "rgba(211,47,47,0.8)",
      main: "rgba(211,47,47,0.9)",
      dark: "rgba(211,47,47,1)",
      contrastText: '#fff'
    },
    secondary: {
      light: "rgb(255,255,255,0.9)",
      main: "rgb(255,255,255,0.97)",
      dark: "rgb(255,255,255,1)",
      contrastText: '#424242'
    }
  },
  menu:{
    main_menu: {
  		title: "sidebar.section.main_menu",
      list: [
        {
  				"menu_title": "sidebar.dashboard",
  				"menu_icon": "view-dashboard",
  				"child_routes": [
            //{"menu_title": "sidebar.dashboard_fleetgroup","path": "/"+moduleName+"/dashboard/fleetgroup",},
            {"menu_title": "sidebar.dashboard_notification",  "path": "/"+moduleName+"/dashboard/notification",},
            {"menu_title": "sidebar.dashboard_fleetconcrete",  "path": "/"+moduleName+"/dashboard/fleetconcrete", allowDataConfig:["concrete"], allowUserRole:["admin","it","sale","executive","xcenter"]},
            {"menu_title": "sidebar.dashboard_fuel",  "path": "/"+moduleName+"/dashboard/fuel",},
  				]
  			},
        {"menu_title": "sidebar.fleet_monitoring", "menu_icon": "car", "path": "/"+moduleName+"/fleet_monitoring",},
        {"menu_title": "sidebar.plans", "menu_icon": "calendar-note", denyOrg:[544], allowDataConfig:["trip"], allowUserRole:["admin","it","xcenter"], "child_routes": [
          {"menu_title": "sidebar.plan_group", "path": "/"+moduleName+"/plan_group",},
          {"menu_title": "sidebar.plan_schedule", "path": "/"+moduleName+"/plan_schedule",},
          {"menu_title": "sidebar.plan_line", "path": "/"+moduleName+"/plan_line",},
          {"menu_title": "sidebar.plan_route", "path": "/"+moduleName+"/plan_route",},
        ]},
        {"menu_title": "sidebar.concrete", "menu_icon": "truck-fast", "icontype":"MaterialCommunityIcons", allowDataConfig:["concrete"], allowUserRole:["admin","it","executive","sale","production","xcenter"], "child_routes": [
          {"menu_title": "sidebar.concrete_plant_search", "path": "/"+moduleName+"/concrete_plant_search",},
          //{"menu_title": "sidebar.concrete_list", "path": "/"+moduleName+"/concrete_list",},
          {"menu_title": "sidebar.concrete_customer_sites", "path": "/"+moduleName+"/concrete_customer_sites",},
          {"menu_title": "sidebar.concrete_plants", "path": "/"+moduleName+"/concrete_plants"},
          {"menu_title": "sidebar.concrete_projects", "path": "/"+moduleName+"/isites/mconcrete/concreteproject", allowOrg:[544]},
        ]},
        /*{
  				"menu_title": "sidebar.map",
  				"menu_icon": "map",
  				"path": "/"+moduleName+"/map",
  				"child_routes": null
  			},*/
      /*  {
  				"menu_title": "sidebar.delivery_order",
  				"menu_icon": "truck",
  				"path": "/"+moduleName+"/delivery_order",
  			},
        {
  				"menu_title": "sidebar.plants",
  				"menu_icon": "accounts-list",
  				"path": "/"+moduleName+"/plants",
  				"child_routes": null
  			},
        {
  				"menu_title": "sidebar.customer_sites",
  				"menu_icon": "accounts-list",
  				"path": "/"+moduleName+"/customer_sites",
  				"child_routes": null
  			},*/
        {"menu_title": "sidebar.documents", "menu_icon": "file-text",allowOrg:[421], "child_routes": [
            { "menu_title": "sidebar.weight_checkpoint", "path": "/"+moduleName+"/weight_checkpoint",allowOrg:[421],},
  			]},
        {"menu_title": "sidebar.report", "menu_icon": "file", "path": "/"+moduleName+"/report", allowUserRole:["admin","it","xcenter","ccenter","production","sale","xcenter"]},
        {"menu_title": "sidebar.settings", "menu_icon": "settings", allowUserRole:["admin","it"], "child_routes": [
          {"menu_title": "sidebar.settings_user", "path": "/"+moduleName+"/settings/user", allowUserRole:["admin"]},
          {"menu_title": "sidebar.settings_notifytoken", "path": "/"+moduleName+"/settings/notifytoken"},
          {"menu_title": "sidebar.settings_notification", "path": "/"+moduleName+"/settings/notification"},
          {"menu_title": "sidebar.settings_fleet", "path": "/"+moduleName+"/settings/fleet",},
          {"menu_title": "sidebar.settings_fleet_s1", "path": "/"+moduleName+"/settings/fleet_s1",},
          {"menu_title": "sidebar.settings_fleet_group", "path": "/"+moduleName+"/settings/fleet_group",},
          {"menu_title": "sidebar.settings_fleet_category", "path": "/"+moduleName+"/settings/fleet_category",},
          {"menu_title": "sidebar.settings_fleet_division", "path": "/"+moduleName+"/settings/fleet_division",},
          {"menu_title": "sidebar.settings_fleet_zone", "path": "/"+moduleName+"/settings/fleet_zone",},
          {"menu_title": "sidebar.settings_fleet_channel", "path": "/"+moduleName+"/settings/fleet_channel",},
  			]},
  		]
  	}
  },
  report_menu:{
    report: {
      list: [
        {"menu_title": "sidebar.report.bus_work", "menu_icon": "bus", allowOrg:[950], "child_routes" : [
            //{"menu_title": "sidebar.report.bus_summarytripscore", "path": "report/bus_summarytripscore"},
            //{"menu_title": "sidebar.report.bus_summarytripdriverscore","path": "report/bus_summarytripdriverscore"},
            //{"menu_title": "sidebar.report.bus_summarytripidle","path": "report/bus_summarytripidle"},
            //{"menu_title": "sidebar.report.bus_parkingplan","path": "report/bus_parkingplan"},
            //{"menu_title": "sidebar.report.bus_summaryontime", "path": "report/bus_summaryontime"},
            {"menu_title": "sidebar.report.bus_summarytripdelay","path": "report/bus_summarytripdelay"},
            {"menu_title": "sidebar.report.bus_summarytripdelayearly","path": "report/bus_summarytripdelayearly", "size":"A3landscape"},
            {"menu_title": "sidebar.report.bus_parkingoverplan","path": "report/bus_parkingoverplan", "size":"A3landscape"},
            {"menu_title": "sidebar.report.bus_parkingoveratdest","path": "report/bus_parkingoveratdest", "size":"A4landscape"},
            {"menu_title": "sidebar.report.bus_parkingoutplan","path": "report/bus_parkingoutplan", "size":"A3landscape"},
            {"menu_title": "sidebar.report.bus_notparkingonplan","path": "report/bus_notparkingonplan", "size":"A3landscape"},
            {"menu_title": "sidebar.report.bus_checkingdaily","path": "report/bus_checkingdaily", "size":"A3landscape"},
            {"menu_title": "sidebar.report.bus_summarytripdelaycarpot","path": "report/bus_summarytripdelaycarpot"},
            {"menu_title": "sidebar.report.bus_summarytripplan","path": "report/bus_summarytripplan", "size":"A3landscape"},
            {"menu_title": "sidebar.report.bus_loginonplan","path": "report/bus_loginonplan", "size":"A3landscape"},
            //{"menu_title": "sidebar.report.bus_outrouteplan","path": "report/bus_outrouteplan", "size":"A3landscape"},
        ]},
        {"menu_title": "sidebar.report.concrete_work", "menu_icon": "truck-fast", "icontype":"MaterialCommunityIcons", allowDataConfig:["concrete"], "child_routes" : [
          {"menu_title": "sidebar.report.concrete_work_by_do_time", "path": "report/concrete_workbydotime"},
          //{"menu_title": "sidebar.report.concrete_work_by_do", "path": "report/concrete_workbydo"},
          //{"menu_title": "sidebar.report.concrete_work_by_vehicle", "path": "report/concrete_workbyvehicle"},
          //{"menu_title": "sidebar.report.concrete_work_trip", "path": "report/concrete_worktrip"},
          //{"menu_title": "sidebar.report.concrete_work_summary_monthly","path": "report/concrete_worksummonthly"},
          {"menu_title": "sidebar.report.concrete_workdodistance","path": "report/concrete_workdodistance"},
        ]},
        {"menu_title": "sidebar.report.trip", "menu_icon": "navigation", allowDataConfig:["trip"], "child_routes" : [
          {"menu_title": "sidebar.report.trip_default", "path": "report/trip_default"},
          {"menu_title": "sidebar.report.trip_consumption", "path": "report/trip_consumption"},
          {"menu_title": "sidebar.report.trip_parking", "path": "report/trip_parking"},
          {"menu_title": "sidebar.report.bus_tripplan", "path": "report/bus_tripplan", allowOrg:[950, 248, 107, 1039, 1132, 1138], "size":"A3landscape"},
        ]},
        {"menu_title": "sidebar.report.summary", "menu_icon": "ios-analytics", "icontype":"Ionicons", allowDataConfig:["trip"], "child_routes" : [
          {"menu_title": "sidebar.report.trip_summarybehavior", "path": "report/trip_summarybehavior", "size":"A3landscape"},
          {"menu_title": "sidebar.report.summary_daily", "path": "report/summary_daily", "size":"A3landscape"},
        ]},
        {"menu_title": "sidebar.report.policy", "menu_icon": "checksquare", "icontype":"AntDesign", allowDataConfig:["trip"], "child_routes" : [
          {"menu_title": "sidebar.report.policy_overspeed","path": "report/policy_overspeed"},
          {"menu_title": "sidebar.report.policy_overspeed_daily","path": "report/policy_overspeed_daily"},
          {"menu_title": "sidebar.report.policy_overspeed_monthly","path": "report/policy_overspeed_monthly"},
          {"menu_title": "sidebar.report.policy_idleoverlimited","path": "report/policy_idleoverlimited"},
        ]},
        {"menu_title": "sidebar.report.fuel_consumption", "menu_icon": "fuel", "icontype":"MaterialCommunityIcons", "child_routes" : [
          {"menu_title": "sidebar.report.chart_fuel_level","path": "report/chart_fuel_level"},
          {"menu_title": "sidebar.report.fuel_level_analysis","path": "report/fuel_level_analysis"},
          //{"menu_title": "sidebar.report.fuel_refilling","path": "report/fuel_refilling"},
        ]},
        {"menu_title": "sidebar.report.oiler", "menu_icon": "gas-station", "child_routes" : [
          //{"menu_title": "sidebar.report.graph_oiler_level","path": "/bestgeo/report/graph_oiler_level"},
          {"menu_title": "sidebar.report.fuel_oiler_level","path": "/bestgeo/report/fuel_oiler_level", "size":"A3landscape"},
        ]},
        /*
        {
          "menu_title": "sidebar.report.driving_behavior",
          "menu_icon": "mood-bad",
          "child_routes" : [

          ]
        },
        {
          "menu_title": "sidebar.report.sensor_option",
          "menu_icon": "memory",
          "child_routes" : [

          ]
        },*/
        {"menu_title": "sidebar.report.documents", "menu_icon": "file-text", allowOrg:[421], "child_routes" : [
            {"menu_title": "sidebar.report.documents_weight_checkpoint", allowOrg:[421], "path": "report/document_weight_checkpoint"},
        ]},
        {"menu_title": "sidebar.report.notification_event", "menu_icon": "notifications", "child_routes" : [
          {"menu_title": "sidebar.report.notification_action", "path": "/bestgeo/report/notification_action"},
        ]},
        {"menu_title": "sidebar.report.detail", "menu_icon": "info-outline", "child_routes" : [
            {"menu_title": "sidebar.report.detail_gpsinfo", "path": "/bestgeo/report/gps_signalinfo?report_option=default"},
            {"menu_title": "sidebar.report.detail_gpsinfo_signal", "path": "/bestgeo/report/gps_signalinfo?report_option=signal_analysis"},
            {"menu_title": "sidebar.report.detail_gpsinfo_event", "path": "/bestgeo/report/gps_signalinfo?report_option=event_analysis"},
            {"menu_title": "sidebar.report.detail_gpsinfo_address", "path": "/bestgeo/report/gps_signalinfo?report_option=address"},
        ]}
      ]
    }
  },
}
