import React from 'react';
import GMap from '../utils/GMap'

const PageERP = React.lazy(() => import('../pages/erp'))
const PageAllDayAllNight = React.lazy(() => import('../pages/alldayallnight'))
const PageSaleOrder = React.lazy(() => import('../pages/saleorder'))
const PageThanachart = React.lazy(() => import('../pages/thanachart'))
const PageWasu = React.lazy(() => import('../pages/wasu'))
const PageTec = React.lazy(() => import('../pages/tec'))
const PageSmartfarm = React.lazy(() => import('../pages/smartfarm'))
const PageFMS = React.lazy(() => import('../pages/fms'))
const PageSurvtrex = React.lazy(() => import('../pages/survtrex'))
const PageFMSCustomer = React.lazy(() => import('../pages/fmscust'))

export const getAppSettings = () => {
  switch(window.location.hostname.replace(".magbizcloud.com", "").replace(".bestgeosystem.com", "")) {
     case "tis-smartfarm":
     case "smartfarm-tis-sugar.com":
     case "smartfarm":
     case "smartfarm-sandbox":
     case "smartfarm.local": {
       GMap.init("th")
       return require('./smartfarm').default
     }
     case "test":
     case "test.local":
     case "fms":
     case "fms.local": {
       GMap.init("th")
       return require('./fms').default
     }
     case "fmscust":
     case "fmscust.local": {
       GMap.init("th")
       return require('./fmscust').default
     }
     case "btportal":
     case "btportal.local": {
       return require('./btportal').default
     }
     case "survtrex":
     case "survtrex.local": {
       GMap.init("th")
       return require('./survtrex').default
     }
     case "thanachart":
     case "thanachart.local": {
        return require('./thanachart').default
     }
     case "wasu":
     case "wasu.local": {
        return require('./wasu').default
     }
     case "tec":
     case "tec.local": {
        return require('./tec').default
     }
     case "place":
     case "alldayallnight":
     case "alldayallnight.local": {
        return require('./alldayallnight').default
     }
     case "saleorder": {
        return require('./saleorder').default
     }
     case "erp":
     case "erp.local":{
       return require('./erp').default
     }
     case "aux":
     case "aux.local": {
       return require('./aux').default
     }
     case "airvenger":
     case "airvenger.local": {
       return require('./airvenger').default
     }
     case "dsa":
     case "dsa.local":
     case "dsa-npm":
     case "dsa-npm.local": {
       return require('./dsa').default
     }
     case "dsa-sandbox":
     case "dsa-sandbox.local": {
       return require('./dsa-sandbox').default
     }
     case "dsapos":
     case "dsapos.local": {
       return require('./dsapos').default
     }
     case "dsapos-sandbox":
     case "dsapos-sandbox.local": {
       return require('./dsapos-sandbox').default
     }
     case "asp":
     case "asp.local": {
       return require('./asp').default
     }
     case "tkpos":
     case "tkpos.local": {
       return require('./tkpos').default
     }
     default: {
        return null
     }
  }
}
export default getAppSettings();

export class AppRoute extends React.Component {

  render() {
    switch(this.props.module_name) {
       case "smartfarm": {
         return (<PageSmartfarm />)
       }
       case "test": {
         return (<PageFMS />)
       }
       case "fms": {
         return (<PageFMS />)
       }
       case "fmscust": {
         return (<PageFMSCustomer />)
       }
       case "survtrex": {
         return (<PageSurvtrex />)
       }
       case "btportal": {
         return (<PageSurvtrex />)
       }
       case "thanachart": {
          return (<PageThanachart />)
       }
       case "wasu": {
          return (<PageWasu />)
       }
       case "tec": {
          return (<PageTec />)
       }
       case "alldayallnight": {
          return (<PageAllDayAllNight />)
       }
       case "saleorder": {
          return (<PageSaleOrder />)
       }
       case "erp": {
          return (<PageERP />)
       }
       case "aux": {
          return (<PageERP />)
       }
       case "airvenger": {
          return (<PageERP />)
       }
       case "dsa":
       case "dsa-sandbox": {
          return (<PageERP />)
       }
       case "dsapos":
       case "dsapos-sandbox": {
          return (<PageERP />)
       }
       case "asp": {
          return (<PageERP />)
       }
       case "tkpos": {
          return (<PageERP />)
       }
       default: {
         return null
       }
    }
  }

}
