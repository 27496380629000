const moduleName = "dsapos-sandbox"

export default {
  name : moduleName,
  api : {
    server :  "https://sandbox.magbizcloud.com/", //"http://192.168.1.103:8082/",  //
    app_module : moduleName,
    timeout: 30000
  },
  title : "DSA POS (Sandbox)",
  description : "Digital sales automation (POS)",
  //logo : "assets/"+moduleName+"/logo.png",                // 128*128 Color Logo
  logo_head : "assets/"+moduleName+"/logo-head.png",      // 200*34 Color Logo
  logo_head_text : "POS System",
  theme : {
    primary: {
      //light: "#2ECC71",
      //main: "#229954",
      //dark: "#1E8449",
      light: "hsl(144, 98%, 50%)",
      main: "hsl(144, 98%, 40%)",
      dark: "hsl(144, 98%, 30%)",
      contrastText: '#fff'
    },
    secondary: {
      light: "rgb(255,255,255,0.9)",
      main: "rgb(255,255,255,0.95)",
      dark: "rgb(255,255,255,1)",
      contrastText: '#424242'
    },
    mytheme: {
      button: "#5F75B4",
      input: "#F4EFE7"
    }
  },
  menu:{
    dashboard: {
      //denyUserRole:["purchase"],
  		list: [
        {"menu_title": "sidebar.dashboard", "menu_icon": "view-dashboard", "path": "/"+moduleName+"/pos/dashboard"},
      ]
    },
    shortcut: {
      denyUserRole:["purchase"],
  		list: [
        {"menu_title": "sidebar.pos", "menu_icon": "shopping-basket", "path": "/"+moduleName+"/pos/main", denyUserRole:["purchase"]},
        {"menu_title": "sidebar.pos_sale_entry", "menu_icon": "collection-text", "path": "/"+moduleName+"/pos/sale_entry", allowUserRole:["admin","shop_manager"]},
        {"menu_title": "sidebar.pos_po_lsg", "menu_icon": "file-text", "path": "/"+moduleName+"/pos/po_lsg", allowUserRole:["admin","shop_manager","cashier"]},
        {"menu_title": "sidebar.pos_goodsrequest", "menu_icon": "file-text", "path": "/"+moduleName+"/pos/goodsrequest", allowUserRole:["admin","shop_manager","cashier2"]},
        {"menu_title": "sidebar.pos_goodsreturn", "menu_icon": "file-text", "path": "/"+moduleName+"/pos/goodsreturn", allowUserRole:["admin","shop_manager","cashier","cashier2"], denies:[{name:"shop", items:["shop"]}]},
        {"menu_title": "sidebar.pos_goodsreceipt", "menu_icon": "file-text", "path": "/"+moduleName+"/pos/goodsreceipt", allowUserRole:["admin","shop_manager","cashier","cashier2"]},
        //{"menu_title": "sidebar.pos_tax_invoice", "menu_icon": "collection-text", "path": "/"+moduleName+"/pos/tax_invoice", allowUserRole:["admin","shop_manager"]},
        {"menu_title": "sidebar.pos_credit","menu_icon": "creditcard", "icontype":"AntDesign", allowUserRole:["admin","shop_manager","purchase","cashier","cashier2"], "child_routes": [
          {"menu_title": "sidebar.pos_creditcustomers","path": "/"+moduleName+"/pos/creditcustomers",},
          {"menu_title": "sidebar.pos_creditorderhistory", "path": "/"+moduleName+"/pos/creditorderhistory",},
        ]},
      ]
    },
    main_menu: {
      title: "sidebar.section.settings",
  		list: [
        {"menu_title": "sidebar.pos_shopsettings", "menu_icon": "shopping-basket", "path": "/"+moduleName+"/pos/shopsettings", allowUserRole:["admin"]},
        {"menu_title": "sidebar.supplier","menu_icon": "city-alt", allowUserRole:["admin","purchase"],"child_routes": [
          {"menu_title": "sidebar.contact_supplier", "path": "/"+moduleName+"/contact/supplier"},
        ]},
        {"menu_title": "sidebar.contact_member", "menu_icon": "account-box-phone", "path": "/"+moduleName+"/contact/member", denyUserRole:["purchase"]},
        {"menu_title": "sidebar.product", "menu_icon": "store", allowUserRole:["admin","shop_manager","purchase","cashier2"], "child_routes": [
          {"menu_title": "sidebar.product_PRD", "path": "/"+moduleName+"/product/PRD", allowUserRole:["admin","shop_manager","purchase"]},
          {"menu_title": "sidebar.product_PRD_uom", "path": "/"+moduleName+"/product/PRD_uom"},
          {"menu_title": "sidebar.product_PRD_brand", "path": "/"+moduleName+"/product/PRD_brand", allowUserRole:["admin","shop_manager","purchase"]},
          {"menu_title": "sidebar.product_PRD_good", "path": "/"+moduleName+"/product/PRD_good", allowUserRole:["admin","shop_manager","purchase"]},
          {"menu_title": "sidebar.product_PRD_category", "path": "/"+moduleName+"/product/PRD_category", allowUserRole:["admin","shop_manager","purchase"]},
          {"menu_title": "sidebar.product_PRD_type", "path": "/"+moduleName+"/product/PRD_type", allowUserRole:["admin","shop_manager","purchase"]},
          {"menu_title": "sidebar.product_PRD_model", "path": "/"+moduleName+"/product/PRD_model", allowUserRole:["admin","shop_manager","purchase"]},
        ]},

        {"menu_title": "sidebar.warehouse","menu_icon": "storage", allowUserRole:["admin","shop_manager","purchase","cashier","cashier2"], "child_routes": [
          {"menu_title": "sidebar.warehouse_location","path": "/"+moduleName+"/warehouse/location",},
          {"menu_title": "sidebar.warehouse_PRD_stock", "path": "/"+moduleName+"/warehouse/PRD_stock",},
          {"menu_title": "sidebar.pos_goodsadjust", "path": "/"+moduleName+"/pos/goodsadjust", allowUserRole:["admin"]},
        ]},

        {"menu_title": "sidebar.sale_promotion", "menu_icon": "money-off", "path": "/"+moduleName+"/sale/promotion", allowUserRole:["admin","shop_manager","purchase"]},
        {"menu_title": "sidebar.settings_coupon", "menu_icon": "card-giftcard", "path": "/"+moduleName+"/settings/coupon", allowUserRole:["admin","shop_manager","purchase"]},
        {"menu_title": "sidebar.user", "menu_icon": "male-female", "path": "/"+moduleName+"/hr/staff", allowUserRole:["admin"]},
  		]
  	},
    /*operation: {
      allowUserRole:["admin","shop_manager","cashier","cashier2"],
  		list: [
        {"menu_title": "sidebar.pos_po_lsg", "menu_icon": "collection-text", "path": "/"+moduleName+"/pos/po_lsg", allowUserRole:["admin","shop_manager","cashier"]},
        {"menu_title": "sidebar.pos_goodsrequest", "menu_icon": "collection-text", "path": "/"+moduleName+"/pos/goodsrequest", allowUserRole:["admin","shop_manager","cashier2"]},
        {"menu_title": "sidebar.pos_goodsreturn", "menu_icon": "collection-text", "path": "/"+moduleName+"/pos/goodsreturn", allowUserRole:["admin","shop_manager","cashier","cashier2"]},
        {"menu_title": "sidebar.pos_goodsreceipt", "menu_icon": "collection-text", "path": "/"+moduleName+"/pos/goodsreceipt", allowUserRole:["admin","shop_manager","cashier","cashier2"]},
      ]
    },*/
    reports: {
  		list: [
        {"menu_title": "sidebar.report", "menu_icon": "file", "path": "/"+moduleName+"/report"},
      ]
    },
  },
  report_menu:{
    report: {
      list: [
        {"menu_title": "sidebar.report.pos_sale", "menu_icon": "money", allowUserRole:["admin","shop_manager","cashier","cashier2"], "child_routes" : [
          {"menu_title": "sidebar.report.pos_sale_se_summary_daily", "path": "report/pos_sale_se_summary_daily", "size":"A3landscape", "exportfilename":"สรุปยอดขายประจำวัน"},
          {"menu_title": "sidebar.report.pos_sale_se_daily", "path": "report/pos_sale_se_daily", "size":"A2landscape", "exportfilename":"สรุปรายละเอียดยอดขายประจำวัน"},
          //{"menu_title": "sidebar.report.pos_sale_se", "path": "report/pos_sale_se", "size":"A4", "exportfilename":"รายงานสรุปบิลขาย"},
          //{"menu_title": "sidebar.report.pos_sale_se_detail", "path": "report/pos_sale_se_detail", "size":"A4landscape", "exportfilename":"รายงานรายละเอียดสินค้าขาย"},
        ]},

        {"menu_title": "sidebar.report.pos_promotion", "menu_icon": "money-off", allowUserRole:["admin","purchase"], "child_routes" : [
          {"menu_title": "sidebar.report.pos_promotion_monthly", "path": "report/pos_promotion_monthly", "size":"A2landscape", "exportfilename": "รายการโปรโมชั่นประจำเดือน"},
        	{"menu_title": "sidebar.report.pos_promotion_summary_sale", "path": "report/pos_promotion_summary_sale", "size":"A2landscape", "exportfilename": "สรุปรายการโปรโมชั่น(ขายออก)"},
        ]},

        {"menu_title": "sidebar.report.pos_stock", "menu_icon": "storage",  "child_routes" : [
          {"menu_title": "sidebar.report.pos_stock_cost_of_good", "path": "report/pos_stock_cost_of_good", "size":"A3landscape", "exportfilename": "แสดงต้นทุนสินค้า", allowUserRole:["admin","purchase"]},
          {"menu_title": "sidebar.report.pos_stock_balance", "path": "report/pos_stock_balance", "size":"A3landscape", "exportfilename":"สต็อคคงเหลือ", allowUserRole:["admin","shop_manager","purchase","cashier","cashier2"]},
          {"menu_title": "sidebar.report.pos_stock_request", "path": "report/pos_stock_request", "size":"A3landscape", "exportfilename": "การเบิกสินค้า", allowUserRole:["admin","shop_manager"]},
        	{"menu_title": "sidebar.report.pos_stock_purchase", "path": "report/pos_stock_purchase", "size":"A3landscape", "exportfilename": "การสั่งซื้อสินค้า", allowUserRole:["admin","shop_manager"]},
        	{"menu_title": "sidebar.report.pos_stock_in", "path": "report/pos_stock_in", "size":"A3landscape", "exportfilename": "การรับสินค้าเข้า", allowUserRole:["admin","shop_manager"]},
        	{"menu_title": "sidebar.report.pos_stock_adjustment", "path": "report/pos_stock_adjustment", "size":"A3landscape", "exportfilename": "การปรับปรุงรายการสินค้า", allowUserRole:["admin"]},
        	{"menu_title": "sidebar.report.pos_stock_activity", "path": "report/pos_stock_activity", "size":"A3landscape", "exportfilename": "ความเคลื่อนไหวสินค้า", allowUserRole:["admin","purchase"]},
        	{"menu_title": "sidebar.report.pos_stock_count", "path": "report/pos_stock_count", "size":"A3landscape", "exportfilename": "Stock Count", allowUserRole:["admin","cashier","cashier2"]},
          {"menu_title": "sidebar.report.wms_stock_checking","path": "report/wms_stock_checking", "size":"A4landscape", "exportfilename":"ใบตรวจนับสินค้า", allowUserRole:["admin"]},
        ]},

        {"menu_title": "sidebar.report.pos_account", "menu_icon": "library", allowUserRole:["admin"], "child_routes" : [
          {"menu_title": "sidebar.report.pos_account_summary_outstanding_recv", "path": "report/pos_account_summary_outstanding_recv", "size":"A3landscape", "exportfilename": "สรุปลูกหนี้ค้างชำระ"},
          {"menu_title": "sidebar.report.pos_account_debtor", "path": "report/pos_account_debtor", "size":"A3landscape", "exportfilename": "รายละเอียดประวัติลูกหนี้ค้างชำระและโปรสะสม"},
          {"menu_title": "sidebar.report.pos_account_sales_tax", "path": "report/pos_account_sales_tax", "size":"A3landscape", "exportfilename": "ภาษีขาย"},
        ]},
      ]
    }
  }
}
