const moduleName = "btportal"

export default {
  name : moduleName,
  api : {
    server : "https://apiservices2.bestgeosystem.com/", //"http://192.168.1.103:8080/", //
    app_module : "btportal",
  },
  title : "BT Portal",
  description : "BestTech Portal System",
  logo : "assets/"+moduleName+"/logo.png",               // 128*128 Color Logo
  logo_head : "assets/"+moduleName+"/logo-head.png",      // 200*34 Color Logo
  theme : {
    primary: {
      light: "rgba(211,47,47,0.8)",
      main: "rgba(211,47,47,0.9)",
      dark: "rgba(211,47,47,1)",
      contrastText: '#fff'
    },
    secondary: {
      light: "rgb(255,255,255,0.9)",
      main: "rgb(255,255,255,0.97)",
      dark: "rgb(255,255,255,1)",
      contrastText: '#424242'
    }
  },
  menu:{
    main_menu: {
  		title: "sidebar.section.main_menu",
      allowOrg:[1],
      list: [
        /*{"menu_title": "sidebar.dashboard", "menu_icon": "view-dashboard", allowUserRole:["admin"], "child_routes": [
            {"menu_title": "sidebar.dashboard_fleetgroup", "path": "/"+moduleName+"/dashboard/fleetgroup",},
  			]},*/
        {"menu_title": "sidebar.service_helpdesk", "menu_icon": "mood", "path": "/"+moduleName+"/service_helpdesk", },
        {"menu_title": "sidebar.service_technician", "menu_icon": "run", "path": "/"+moduleName+"/service_technician", },
        {"menu_title": "sidebar.task",  "menu_icon": "assignment", "path": "/"+moduleName+"/task", denyUserRole: ["harvester","driver"]},
        {"menu_title": "sidebar.knowledgebase",  "menu_icon": "book", "path": "/"+moduleName+"/knowledgebase", allowUserRole: ["admin","it","pm_admin"]},
        /*{"menu_title": "sidebar.project_management", "menu_icon": "developer-board", allowUserRole:["admin"], "child_routes": [
            {"menu_title": "sidebar.project", "path": "/"+moduleName+"/project",},
  			]},*/
        {"menu_title": "sidebar.hr", "menu_icon": "account", allowUserRole: ["admin"], "child_routes": [
          {"menu_title": "sidebar.hr_staff", "path": "/"+moduleName+"/hr/staff",},
          {"menu_title": "sidebar.hr_department", "path": "/"+moduleName+"/hr/department",},
        ]},
        {"menu_title": "sidebar.warehouse", "menu_icon": "store", allowUserRole: ["admin","stock","stock_admin","account_admin","hr_admin","account_admin","cs_admin"], "child_routes": [
          {"menu_title": "sidebar.warehouse_PRD_stock", "path": "/"+moduleName+"/warehouse/PRD_stock",},
          {"menu_title": "sidebar.warehouse_PRD", "path": "/"+moduleName+"/warehouse/PRD",},
          {"menu_title": "sidebar.warehouse_PRD_category", "path": "/"+moduleName+"/warehouse/PRD_category",},
          {"menu_title": "sidebar.warehouse_PRD_type", "path": "/"+moduleName+"/warehouse/PRD_type",},
          {"menu_title": "sidebar.warehouse_PRD_brand", "path": "/"+moduleName+"/warehouse/PRD_brand",},
          {"menu_title": "sidebar.warehouse_PRD_model", "path": "/"+moduleName+"/warehouse/PRD_model",},
        ]},
        {"menu_title": "sidebar.warehouse_asm_supplies", "menu_icon": "store-24", allowUserRole: ["admin","stock","stock_admin","pm_admin","hr_admin","account_admin","cs_admin"], "child_routes": [
          {"menu_title": "sidebar.warehouse_AST_stock", "path": "/"+moduleName+"/warehouse/AST_stock",},
          {"menu_title": "sidebar.warehouse_SPY_stock", "path": "/"+moduleName+"/warehouse/SPY_stock",},
          {"menu_title": "sidebar.warehouse_AST", "path": "/"+moduleName+"/warehouse/AST",},
          {"menu_title": "sidebar.warehouse_SPY", "path": "/"+moduleName+"/warehouse/SPY",},
          {"menu_title": "sidebar.warehouse_AST_type", "path": "/"+moduleName+"/warehouse/AST_type",},
          {"menu_title": "sidebar.warehouse_SPY_type", "path": "/"+moduleName+"/warehouse/SPY_type",},
        ]},
        {"menu_title": "sidebar.account", "menu_icon": "balance-wallet", allowUserRole: ["admin","account","account_admin"], "child_routes": [
          {"menu_title": "sidebar.account_receivable", "path": "/"+moduleName+"/account_receivable",},
          {"menu_title": "sidebar.account_invoice", "path": "/"+moduleName+"/account/invoice",},
          {"menu_title": "sidebar.account_receipt", "path": "/"+moduleName+"/account/receipt",},
        ]},
        {"menu_title": "sidebar.settings","menu_icon": "settings", allowUserRole:["admin"],"child_routes": [
            {"menu_title": "sidebar.warehouse_location","path": "/"+moduleName+"/warehouse/location",},
            {"menu_title": "sidebar.warehouse_VHC_brand","path": "/"+moduleName+"/warehouse/VHC_brand",},
            {"menu_title": "sidebar.knowledge_group","path": "/"+moduleName+"/knowledge_group",},
  			]},
  		]
  	},
    fleet_portal: {
      title: "sidebar.section.fleet_portal",
      allowOrg:[1],
      denyUserRole:["technician_temp"],
      list: [
        /*{"menu_title": "sidebar.customer", "menu_icon": "city-alt", "path": "/"+moduleName+"/customer", allowUserRole:["admin"],},
        {"menu_title": "sidebar.fleet", "menu_icon": "car", "path": "/"+moduleName+"/fleet", allowUserRole:["admin"],},
        {"menu_title": "sidebar.user", "menu_icon": "male-female", "path": "/"+moduleName+"/user", allowUserRole:["admin"],},
        {"menu_title": "sidebar.dlt", "menu_icon": "disqus", allowUserRole:["admin"], "child_routes": [
          {"menu_title": "sidebar.dlt_cer", "path": "/"+moduleName+"/dlt_cer",},
  			]},*/
        {"menu_title": "sidebar.technical_investigation", "menu_icon": "badge-check", "path": "/"+moduleName+"/technical_investigation", denyUserRole: ["harvester","driver"]},
        {"menu_title": "sidebar.report", "menu_icon": "file", "path": "/"+moduleName+"/report", allowUserRole: ["admin","it","pm_admin","hr_admin","engineer","technician_admin","cs_admin","sale_head"]},
        {"menu_title": "sidebar.logviewer", "menu_icon": "file", "path": "/"+moduleName+"/logviewer", allowOnlyUser:["admin","ton"]},
      ]
    },
  },
  report_menu:{
    report: {
      allowOrg:[1],
      list: [
        {"menu_title": "sidebar.report.detail", "menu_icon": "info-outline", allowOrg:[1], "child_routes" : [
          {"menu_title": "sidebar.report.detail_gpsinfo", "path": "report/gps_signalinfo?report_option=default"},
          {"menu_title": "sidebar.report.detail_gpsinfo_signal", "path": "report/gps_signalinfo?report_option=signal_analysis"},
          {"menu_title": "sidebar.report.detail_gpsinfo_event", "path": "report/gps_signalinfo?report_option=event_analysis"},
          {"menu_title": "sidebar.report.detail_gpsinspection", "path": "report/gps_inspection"},
        ]},
        {"menu_title": "sidebar.report.operation", "menu_icon": "calendar-note", allowOrg:[1], "child_routes" : [
          {"menu_title": "sidebar.report.operation_summarysobycreator", "path": "report/operation_summarysobycreator"},
          {"menu_title": "sidebar.report.operation_summarysobytechnician", "path": "report/operation_summarysobytechnician"},
          {"menu_title": "sidebar.report.operation_so", "path": "report/operation_so", "size":"A2landscape"},
        ]},
      ]
    }
  },
}
