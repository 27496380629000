module.exports = {
	"sidebar.section.main_menu": "เมนูหลัก",
	"sidebar.section.fleet_portal": "Fleet Portal",
	"sidebar.dashboard": "Dashboard",
	"sidebar.dashboard_fleetgroup": "Fleet Group Dashboard",
	"sidebar.dashboard_concrete1": "Concrete Dashboard",
	"sidebar.dashboard_concrete_truck_available": "Concrete Truck Available",
	"sidebar.dashboard_concrete_truck_monitoring": "Truck Monitoring",
	"sidebar.dashboard_concrete_plant_volume": "Plant Volume",
	"sidebar.dashboard_concrete_monitoring": "Concrete Monitoring",
	"sidebar.dashboard_concrete_driver_truck_performance": "Driver & Truck Performance",
	"sidebar.dashboard_concrete_truck_performance": "Truck Performance",
	"sidebar.dashboard_concrete_driver_performance": "Driver Performance",
	"sidebar.dashboard_notification": "Notification Dashboard",

	"sidebar.customer_service" : "Customer Service",
	"sidebar.service_technician" : "Service Order",
	"sidebar.service_helpdesk" : "Helpdesk Ticket",
	"sidebar.service_best" : "Best Service",
	"sidebar.knowledgebase" : "Information Center",
	"sidebar.project_management" : "Project",
	"sidebar.project" : "Projects",
	"sidebar.task" : "Task Request",
	"sidebar.hr" : "พนักงาน (ผู้ใช้ระบบ)",
	"sidebar.hr_staff" : "รายชื่อผู้ใช้งานระบบ",
	"sidebar.hr_department" : "แผนก",
	"sidebar.hr_division" : "หน่วยงาน",
	"sidebar.location" : "สถานที่",
	"sidebar.location_place" : "พิกัดสถานที่",
	"sidebar.location_field" : "แปลงพื้นที่",
	"sidebar.contact": "รายชื่อผู้ติดต่อ",
	"sidebar.contact_customer": "ลูกค้า",
	"sidebar.contact_supplier": "ผู้จำหน่าย",
	"sidebar.contact_driver" : "ผู้ขับรถ",
	"sidebar.contact_farmer": "ชาวไร่",
	"sidebar.contact_vendor": "ผู้ให้บริการ (Vendor)",
	"sidebar.contact_trucker": "ผู้ขับรถบรรทุก",
	"sidebar.contact_harvester": "ผู้ขับรถตัด",
	"sidebar.contact_cablecardriver": "ผู้ขับรถไถลาก-กระเช้า",
	"sidebar.contact_person": "ผู้ติดต่อรายบุคคล",
	"sidebar.contact_truckerrequest" : "ผู้ขับรถบรรทุกขอรับงาน",
	"sidebar.warehouse" : "คลังสินค้า",
	"sidebar.warehouse_PRD_stock" : "สินค้า",
	"sidebar.warehouse_PRD" : "รายการสินค้า",
	"sidebar.warehouse_PRD_category" : "หมวดหมู่สินค้า",
	"sidebar.warehouse_PRD_type" : "ประเภทสินค้า",
	"sidebar.warehouse_PRD_brand" : "แบรนด์สินค้า",
	"sidebar.warehouse_PRD_model" : "รุ่นสินค้า",
	"sidebar.warehouse_asm_supplies": "คลังอุปกรณ์และวัสดุ",
	"sidebar.warehouse_AST_stock": "อุปกรณ์",
	"sidebar.warehouse_AST": "รายการอุปกรณ์",
	"sidebar.warehouse_AST_type": "ประเภทอุปกรณ์",
	"sidebar.warehouse_SPY_stock": "วัสดุสิ้นเปลือง",
	"sidebar.warehouse_SPY": "รายการวัสดุสิ้นเปลือง",
	"sidebar.warehouse_SPY_type": "ประเภทวัสดุสิ้นเปลือง",
	"sidebar.account": "บัญชี - การเงิน",
	"sidebar.account_invoice": "ใบแจ้งหนี้",
	"sidebar.account_invoicebill": "ใบวางบิล/ใบแจ้งหนี้",
	"sidebar.account_receipttaxinvoice": "ใบกำกับภาษี/ใบเสร็จรับเงิน",
	"sidebar.account_receipt": "ใบเสร็จรับเงิน",
	"sidebar.account_taxinvoice": "ใบกำกับภาษี",
	"sidebar.account_receivable" : "รายการเรียกเก็บเงิน",
	"sidebar.concrete":"งานโม่ปูน",
	"sidebar.concrete_do":"ใบส่งของ (DO)",
	"sidebar.concrete_vehicle_check":"การตรวจสอบสภาพรถ",
	"sidebar.concrete_truck_monitoring":"Truck Monitoring",
	"sidebar.concrete_truck": "สถานะรถโม่ปูน",
	"sidebar.operation": "แบบฟอร์มเอกสาร",
	"sidebar.operation_pr": "ใบขอซื้อ",
	"sidebar.operation_eqr": "ใบเบิกเครื่องมือ",
	"sidebar.operation_pdr": "ใบเบิกสินค้า",
	"sidebar.form_ot": "ใบเบิกค่าล่วงเวลา (OT)",
	"sidebar.form_leave": "ใบลา (Leave Form)",
	"sidebar.settings": "การตั้งค่า",
	"sidebar.settings_company": "ตั้งค่าองค์กร",
	"sidebar.settings_location": "คลังเก็บของ",
	"sidebar.settings_vehicle": "รถลงทะเบียน",
	"sidebar.settings_project_vehicle_group" : "กลุ่มรถร่วมโครงการ",
	"sidebar.company_settings" : "ตั้งค่าบริษัท",
	"sidebar.account_settings" : "ตั้งค่าผู้ใช้งาน",
	"sidebar.warehouse_location": "คลังเก็บของ",
	"sidebar.warehouse_VHC_brand": "แบรนด์รถ",
	"sidebar.knowledge_group": "กลุ่ม Knowledge",
	"sidebar.logviewer": "Log Viewer",

	"sidebar.customer" : "ลูกค้า",
	"sidebar.fleet" : "รายการรถ",
	"sidebar.user" : "ผู้ใช้งานระบบ Fleet",
	"sidebar.dlt" : "DLT",
	"sidebar.dlt_cer" : "หนังสือรับรองการติดตั้ง",
	"sidebar.technical_investigation" : "ตรวจสอบข้อมูล",
	"sidebar.report": "รายงาน",
	"sidebar.report.detail": "ข้อมูล GPS",
	"sidebar.report.detail_gpsinfo": "ข้อมูลพื้นฐานจากอุปกรณ์",
	"sidebar.report.detail_gpsinfo_signal": "ข้อมูลระดับสัญญาณและเซ็นเซอร์",
	"sidebar.report.detail_gpsinfo_event": "ข้อมูลเฉพาะเหตุการณ์ที่สนใจ",
	"sidebar.report.detail_gpsinspection": "สรุปข้อมูลการตรวจสอบ GPS",
	"sidebar.report.operation": "การปฏิบัติงาน",
	"sidebar.report.operation_summarysobycreator": "สรุปงาน Service Order (ผู้ตรวจสอบ)",
	"sidebar.report.operation_summarysobytechnician": "สรุปงาน Service Order (ผู้ปฏิบัติงาน)",
	"sidebar.report.operation_so": "การฏิบัติงาน Service Order",
	"sidebar.report.operation_bso": "การปฏิบัติงานรายบุคคล",
	"sidebar.report.operation_bso_checkinoutdaily": "การเข้า-ออกงานรายวัน",
	"sidebar.report.operation_bso_checkinoutlocation": "การ Checkin-Out สถานที่",
	"sidebar.report.notification_event": "การแจ้งเตือน-เหตุการณ์",
	"sidebar.report.notification_action": "การแจ้งเตือนและตรวจสอบ",
	"sidebar.report.hr": "ฝ่ายบุคคล",
	"sidebar.report.hr_dailyworktime": "เวลาการทำงานรายวัน",
	"sidebar.report.hr_worktime_mcon": "การเข้า-ออกงาน (MConcrete)",
	"sidebar.report.concrete": "รถโม่ปูน",
	"sidebar.report.concrete_truck_daily": "ประสิทธิภาพการทำงานของรถโม่ปูน",
	"sidebar.report.concrete_stafftruck_daily": "การปฏิบัติงานพนักงานขับรถโม่ปูน",
	"sidebar.report.concrete_work_by_do_time": "เวลาการทำงานของรถโม่ปูน (DO)",
	"sidebar.report.concrete_workdodistance": "เปรียบเทียบระยะทางจากแผนงาน",
	"sidebar.report.concrete_truck_repair": "การแจ้งซ่อมรถโม่ปูน",

	"orgsetting.title": "ตั้งค่าองค์กร",
	"orgsetting.code" : "รหัสบริษัท",
	"orgsetting.name": "ชื่อบริษัท",
	"orgsetting.profile_info" : "ข้อมูลกิจการ",
	"orgsetting.other_info" : "การตั้งค่าอื่นๆ",
	"orgsetting.nameth": "ชื่อตามเอกสาร",
	"orgsetting.nameen": "ชื่อตามเอกสาร (EN)",
	"orgsetting.contactth": "ข้อมูลหัวเอกสาร",
	"orgsetting.contacten": "ข้อมูลหัวเอกสาร (EN)",
	"orgsetting.taxno": "เลขประจำตัวผู้เสียภาษี",
	"orgsetting.photo_logo": "รูปภาพโลโก้บริษัท",
	"orgsetting.timein": "เวลาเข้างาน",
	"orgsetting.timeout": "เวลาออกงาน",

	"erp.doc_no" : "เลขที่เอกสาร",
	"erp.ref_doc_no" : "เอกสารอ้างอิง",
	"erp.project" : "โครงการ",
	"erp.doc_date" : "วันที่",
	"erp.total_amount" : "จำนวนเงิน",
	"erp.discount_amount" : "ส่วนลด",
	"erp.value_amount" : "มูลค่า",
	"erp.vat_amount" : "ภาษีมูลค่าเพิ่ม",
	"erp.net_amount" : "รวมทั้งสิ้น",
	"erp.net_amount2" : "ยอดเงิน",
	"erp.item_id" : "รหัส",
	"erp.item_name" : "รายการ",
	"erp.item_num" : "จำนวน",
	"erp.item_unit_price" : "ราคา/หน่วย",
	"erp.item_amount" : "จำนวนเงิน",
	"erp.payment_condition": "รายละเอียดเงื่อนไขการชำระเงิน",
	"erp.docviewtype" : "ประเภทเอกสาร",
	"erp.docprinttype": "ต้นฉบับ/สำเนา",
	"erp.docprinttype.original" : "ต้นฉบับ (Original)",
	"erp.docprinttype.copy" : "สำเนา (Copy)",

	"dashboard.plant" : "แพล้นท์",
	"dashboard.site" : "ไซด์งาน",
	"dashboard.vehicle" : "เบอร์รถ",
	"dashboard.truck_status" : "สถานะรถ",


	"receipt.title" : "ใบเสร็จรับเงิน",
	"receipt.title2" : "Receipt",
	"receipt.customer" : "ชื่อบริษัท",
	"receipt.value_amount" : "จำนวนเงินหลังหักส่วนลด",
	"receipt.received_by" : "ผู้รับสินค้า",
	"receipt.financial_by" : "ผู้รับเงิน",
	"receipt.authorized_by" : "ผู้รับมอบอำนาจ",

	"receipttaxinvoice.title" : "ใบกำกับภาษี/ใบเสร็จรับเงิน",
	"receipttaxinvoice.title2" : "Tax Invoice/Receipt",

	"taxinvoice.title" : "ใบกำกับภาษี",
	"taxinvoice.title2" : "Tax Invoice",

	"invoice.title" : "ใบแจ้งหนี้",
	"invoice.title2" : "Invoice",
	"invoice.customer" : "ชื่อบริษัท",
	"invoice.payment_term" : "เงื่อนไขการชำระเงิน",
	"invoice.po" : "ใบสั่งซื้ออ้างอิง",
	"invoice.value_amount" : "จำนวนเงินหลังหักส่วนลด",
	"invoice.authorized_by" : "ผู้มีอำนาจลงนาม",
	"invoice.checked_by" : "ผู้ตรวจสอบ",
	"invoice.received_by" : "ผู้รับสินค้า",

	"invoicebill.title" : "ใบวางบิล/ใบแจ้งหนี้",
	"invoicebill.title2" : "Billing Note/Invoice",

	"staff.title" : "พนักงาน",
	"staff.profile" : "ข้อมูลพนักงาน",
	"staff.profile_image" : "รูปถ่าย",
	"staff.card" : "บัตรประชาชน",
	"staff.staff_code" : "รหัสพนักงาน (ถ้ามี)",
	"staff.code" : "รหัสอ้างอิง",
	"staff.username" : "Username",
	"staff.name" : "ขื่อพนักงาน",
	"staff.surname" : "นามสกุล",
	"staff.nickname" : "ชื่อเล่น",
	"staff.position" : "ตำแหน่ง",
	"staff.department" : "แผนก",
	"staff.division" : "หน่วยงาน",
	"staff.phone": "เบอร์โทร",
	"staff.email": "อีเมลล์",
	"staff.birthday": " วันเกิด",
	"staff.gender": "เพศ",
	"staff.card_id": "เลขบัตรประชาชน",
	"staff.role" : "User Role",
	"staff.registered_date": "วันที่เริ่มงาน",
	"staff.resigned_date": "วันที่ออกจากงาน",
	"staff.staff_status": "สถานะการจ้างงาน",
	"staff.staff_type": "ประเภทพนักงาน",
	"staff.user_status": "สถานะ User",
	"staff.password": "รหัสผ่านเข้าใช้งานระบบ",
	"staff.benefit": "ค่าตอบแทน",
	"staff.salary": "เงินเดือน",
	"staff.labour": "ค่าจ้าง (กรณีจ้างรายวัน)",
	"staff.timework": "เวลาการทำงาน",
	"staff.timein": "เวลาเข้างาน",
	"staff.timeout": "เวลาเลิกงาน",
	"staff.authorized": "สิทธิ์ในระบบ (นอกเหนือจากสิทธิ์ตาม User Role)",
	"staff.supervisor": "หัวหน้างาน",
	"staff.status.Inactive": "In-active",
	"staff.status.Active": "Active",

	"contact.title_additional":"(เพิ่มเติม)",
	"contact.yearly": "ปีลงทะเบียน",
	"contact.profile_info" : "ข้อมูลทั่วไป",
	"contact.farmer_info": "ข้อมูลชาวไร่",
	"contact.profile_attach": "เอกสารแนบ",
	"contact.profile_status": "สถานะรับงาน",
	"contact.profile_status_time": "เวลาที่อัพเดทสถานะ",
	"contact.attach_profile": "ภายถ่ายล่าสุด",
	"contact.attach_card": "บัตรประชาชน",
	"contact.attach_home": "ทะเบียนบ้าน",
	"contact.attach_bank": "หน้าสมุดบัญชี",
	"contact.attach_request": "เอกสารคำร้อง",
	"contact.last_updated" : "อัพเดทล่าสุด",
	"contact.address" : "ข้อมูลที่อยู่หลัก (ตามเอกสาร)",
	"contact.address_no" : "เลขที่อยู่/อาคาร/หมู่บ้าน/ถนน/ซอย",
	"contact.region" : "เขตตำบล",
	"contact.zipcode" : "รหัสไปรษณีย์",
	"contact.vehicle": "ข้อมูลรถที่ขับขี่",
	"contact.vehicle_no": "เบอร์รถ",
	"contact.trailer_no": "รถพ่วง",
	"contact.tractor_no": "รถไถลาก",
	"contact.cablecar_no": "รถกระเช้า",
	"contact.vehicle_gps": "รถที่ติดตั้ง GPS",
	"contact.phone" : "เบอร์โทร",
	"contact.sex":"เพศ",
	"contact.birthdate":"วันเกิด",
	"contact.marriage":"สถานะคู่ครอง",
	"contact.fax" : "แฟกซ์",
	"contact.email" : "อีเมลล์",
	"contact.line" : "Line",
	"contact.website" : "เว็บไซด์",
	"contact.company_name" : "ชื่อบริษัท",
	"contact.nickname" : "ชื่อเล่น",
	"contact.education":"การศึกษา",
	"contact.abbrname" : "ชื่อย่อ",
	"contact.credit" : "เครดิต",
	"contact.branch" : "สาขา",
	"contact.branch_hint" : "(สำนักงานใหญ่)",
	"contact.company_ref" : "Vendor",
	"contact.remark" : "หมายเหตุ",
	"contact.contact" : "ข้อมูลผู้ติดต่อหลัก",
	"contact.contact_name": "ชื่อผู้ติดต่อหลัก",
	"contact.contact_phone": "เบอร์โทร",
	"contact.comment": "รายละเอียดการพิจารณา",
	"contact.taxno" : "เลขผู้เสียภาษี",
	"contact.cardno" : "เลขบัตรประชาชน",
	"contact.card_expire": "หมดอายุ",
	"contact.bank_info" : "ข้อมูลบัญชีธนาคารหลัก",
	"contact.bank" : "ธนาคาร",
	"contact.bank_account" : "เลขที่บัญชี",
	"contact.bank_branch" : "สาขา",
	"contact.bank_acc_type" : "ประเภทบัญชี",
	"contact.account_name" : "ชื่อบัญชี",
	"contact.account" : "ข้อมูลทางบัญชี",
	"contact.account_no": "รหัสบัญชี",
	"contact.contact_type" : "ประเภทผู้ติดต่อ",
	"contact.doc_type" : "ประเภทการติดต่อ",
	"contact.doc_date": "วันที่ลงทะเบียน",
	"contact.follower": "พนง.ติดตาม",
	"contact.status.D" : "เอกสารใหม่",
	"contact.status.S" : "ส่งข้อมูล",
	"contact.status.V" : "ยืนยันข้อมูล",
	"contact.status.R" : "ไม่อนุมัติ",
	"contact.status.A1" : "อนุมัติ",
	"contact.status.A" : "อนุมัติเปิดใช้งาน",
	"contact.status.N" : "ปิดสถานะการใช้งาน",
	"contact.status.C" : "ยกเลิก",
	"contact.fleet_channel": "Channel รถขนส่ง",
	"contact.fleet_zone": "โซนรถตัด",
	"contact.all_jobs": "งานทั้งหมด",
	"contact.on_process_jobs": "งานค้าง",
	"contact.last_closed_job_time": "ปิดงานล่าสุด",
	"contact.last_job_time": "จ่ายงานล่าสุด",

	"customer.title" : "บริษัทลูกค้า",
	"customer.name" : "ชื่อธุรกิจ",
	"customer.doc_no" : "รหัสลูกค้า",
	"customer.owner": "ผู้ดูแล",
	"supplier.title" : "Supplier",
	"supplier.name" : "ชื่อธุรกิจ",
	"supplier.doc_no" : "รหัสอ้างอิง",
	"supplier.owner": "ผู้ดูแล",
	"person.title" : "ผู้ติดต่อ",
	"person.name" : "ชื่อผู้ติดต่อ",
	"person.doc_no" : "รหัสผู้ติดต่อ",
	"driver.title" : "ผู้ขับรถ",
	"driver.name" : "ชื่อผู้ขับ",
	"driver.doc_no" : "รหัสผู้ขับ",
	"vendor.title" : "ทะเบียนผู้ให้บริการ",
	"vendor.name" : "ชื่อองค์กร",
	"vendor.doc_no" : "รหัสอ้างอิง",
	"vendor.owner": "ผู้ดูแล",
	"trucker.title" : "ทะเบียนผู้ขับรถบรรทุก",
	"trucker.name" : "ชื่อ-สกุล",
	"trucker.doc_no" : "รหัสผู้ขับ",
	"harvester.title" : "ทะเบียนผู้ขับรถตัด",
	"harvester.name" : "ชื่อ-สกุล",
	"harvester.doc_no" : "รหัสผู้ขับ",
	"cablecardriver.title" : "ทะเบียนผู้ขับรถไถลาก",
	"cablecardriver.name" : "ชื่อ-สกุล",
	"cablecardriver.doc_no" : "รหัสผู้ขับ",
	"farmer.title" : "คำขอขึ้นทะเบียนชาวไร่",
	"farmer.name" : "ชื่อ-สกุล",
	"farmer.doc_no" : "รหัสอ้างอิง",
	"farmer.owner": "นักส่งเสริม",
	"farmer.division" : "เขต/สาย",
	"farmer.zone" : "โซนความรับผิดชอบ",
	"farmer.group":"รหัสหัวหน้ากลุ่ม",
	"farmer.farmer_card": "บัตรชาวไร่",
	"farmer.farmer_type" : "ประเภทชาวไร่",
	"farmer.farmer_grade" : "เกรดชาวไร่",
	"farmer.contract_info": "ข้อมูลสัญญาส่งอ้อย",
	"farmer.contract_no": "เลขที่สัญญา",
	"farmer.contract_no_hint": "เปิดสัญญาใหม่",
	"farmer.contract_date": "วันที่ในสัญญา",
	"farmer.yearly": "ปีการ",
	"farmer.yearly_hint": "ปัจจุบัน",
	"farmer.volume": "สัญญาตัน",
	"farmer.credit": "ประสงค์ของสินเชื่อประเภท",
	"farmer.credit_amount": "วงเงินสินเชื่อ",
	"farmer.payment": "การขอรับเงิน",
	"farmer.payment_option": "ขอรับเงินอื่นๆ ระบุ",
	"farmer.collateral_info": "ข้อมูลหลักทรัพทย์ค้ำประกัน",
	"farmer.collateral_type": "หลักทรัพย์",
	"farmer.collateral_val": "มูลค่า",
	"farmer.collateral_desc": "รายละเอียดหลักทรัพย์",
	"farmer.plant_info": "ข้อมูลการปลูกอ้อย",
	"farmer.land_owner": "ที่ดินตนเอง/ครอบครัว",
	"farmer.land_rent": "ที่ดินเช่า/บุคคลอื่น",
	"farmer.land_other": "ที่ดินอื่นๆ",
	"farmer.seed":"พันธุ์อ้อยที่ใช้ปลูก",
	"farmer.chemical":"สารเคมีที่ใช้",
	"farmer.delivery_info": "ข้อมูลการขนส่งอ้อยเข้าหีบ",
	"farmer.truck_manage": "การจัดการรถบรรทุก",
	"farmer.truck_owner": "รถบรรทุกที่มี (กรณีมีรถบรรทุกเป็นของตัวเอง)",
	"farmer.status_info": "ข้อมูลความพร้อมในการปลูกอ้อย",
	"farmer.tractor_big": "รถไถขนาดใหญ่",
	"farmer.tractor_med": "รถไถขนาดกลาง",
	"farmer.plant_machine": "เครื่องปลูกอ้อย",
	"farmer.grabber": "เครื่องคีบอ้อย",
	"farmer.harvester": "รถตัดอ้อย",
	"farmer.selling_info": "ข้อมูลการส่งอ้อย/ขายอ้อย",
	"farmer.sell1": "การส่ง/ขายอ้อย 1",
	"farmer.sell2": "การส่ง/ขายอ้อย 2",
	"farmer.sell_farmer": "ชาวไร่รายใหญ่ (กรณีส่งให้ชาวไร่รายใหญ่)",
	"farmer.sell_fac": "โรงงาน (กรณีขายเงินสดหน้าโรงงาน)",
	"farmer.sellingh_info": "ประวัติการส่งอ้อย",
	"farmer.sell_h1": "เคยส่งอ้อยให้ (ชื่อโรงงาน/ปีการผลิต/จำนวนตัน)",
	"farmer.sell_h2": "เคยส่งอ้อยให้ (ชื่อโรงงาน/ปีการผลิต/จำนวนตัน)",
	"farmer.sell_c1": "มีสัญญาส่งอ้อยกับ (ชื่อโรงงาน/จำนวนตัน)",
	"farmer.sell_c2": "มีสัญญาส่งอ้อยกับ (ชื่อโรงงาน/จำนวนตัน)",
	"farmer.other_info": "ข้อมูลอื่นๆ",
	"farmer.member": "เป็นสมาชิกสมาคมชาวไร่",
	"farmer.member_hint":"ยังไม่เป็นสมาชิกที่ใดมาก่อน",
	"farmer.regist": "รหัสประจำตัวชาวไร่ (กรณีจดทะเบียนแล้ว)",
	"farmer.regist_hint":"ไม่ได้จดทะเบียนชาวไร่",
	"farmer.job": "ประกอบอาชีพหลัก",
	"farmer.job_hint":"ชาวไร่อ้อย",
	"farmer.other": "ข้อมูลอื่นๆ (เพิ่มเติม)",
	"farmer.comment": "รายละเอียดการพิจารณา",

	"place.title" : "พิกัดสถานที่",
	"place.name" : "ชื่อสถานที่",
	"place.doc_no" : "รหัสสถานที่",
	"place.location_type" : "ประเภทสถานที่",
	"place.ref_id" : "องค์กร/บริษัท/ลูกค้า",
	"place.lat" : "ละติจูด",
	"place.lng" : "ลองจิจูด",
	"place.radius" : "รัศมี (เมตร)",

	"field.role" : "ประเภท",

	"project.customer" : "ลูกค้า",
	"project.doc_date" : "วันที่เริ่ม",
	"project.doc_no" : "Project No",
	"project.project" : "ชื่อโครงการ",
	"project.ref_doc_no" : "เลขที่สัญญา",
	"project.pm" : "PM",
	"project.net_amount" : "มูลค่าโครงการ",
	"project.description" : "รายละเอียดโครงการ",
	"project.payment" : "การชำระเงิน",
	"project.project_type" : "ประเภทโครงการ",
	"project.finished_date" : "วันที่สิ้นสุด",

	"fleet.fleet_group" : "กลุ่มรถ",
	"fleet.title" : "ข้อมูลการติดตั้งอุปกรณ์",
	"fleet.imei" : "IMEI / Serial No",
	"fleet.phone_no" : "เบอร์ซิม",
	"fleet.gps_model" : "รุ่นเครื่อง",
	"fleet.regist_no" : "เบอร์รถ",
	"fleet.status.Active":"เปิดใช้งาน",
	"fleet.status.Inactive":"ถอด (ยกเลิก)",

	"technical_investigation.title" : "ข้อมูลรถและอุปกรณ์",
	"technical_investigation.imei" : "IMEI / Serial No",
	"technical_investigation.phone_no" : "เบอร์ซิม",
	"technical_investigation.vehicle_no" : "เบอร์รถ",
	"technical_investigation.chassis_no" : "เลขตัวถัง",
	"technical_investigation.regist_no" : "ทะเบียนรถ",
	"technical_investigation.vehicle_brand" : "ยี่ห้อรถ",
	"technical_investigation.register_type" : "ลักษณะรถ",
	"technical_investigation.gps_dlt" : "การเชื่อมต่อ",
	"technical_investigation.current_driver_id": "เลขที่ใบขับขี่",
	"technical_investigation.province" : "จังหวัด",
	"technical_investigation.vstatus" : "สถานะการใช้งาน",
	"technical_investigation.firmware" : "Firmware",
	"technical_investigation.device_status" : "สถานะอุปกรณ์",
	"technical_investigation.gps_model" : "รุ่นเครื่อง",
	"technical_investigation.card_reader_brand" : "เครื่องรูดบัตร",
	"technical_investigation.vehicle_model_id" : "การตั้งค่าน้ำมัน",
	"technical_investigation.install_date" : "วันที่ติดตั้ง",
	"technical_investigation.expire_date" : "วันหมดอายุ Package",
	"technical_investigation.vehicle_role" : "ประเภทการติดตั้ง",
	"technical_investigation.owner_name" : "ชื่อเจ้าของรถ",
	"technical_investigation.owner_tax_no" : "เลขผู้เสียภาษี",
	"technical_investigation.owner_address" : "ที่อยู่",
	"technical_investigation.org_name" : "ชื่อลุกค้า/องค์กร",
	"technical_investigation.org_contact_name" : "ชื่อผู้ติดต่อ",
	"technical_investigation.org_phone" : "เบอร์ผู้ติดต่อ",
	"technical_investigation.org_email" : "อีเมลล์ผู้ติดต่อ",
	"technical_investigation.org_package" : "Package",
	"technical_investigation.org_sale" : "ผู้ขาย",
	"technical_investigation.latest_rcdate" : "วันที่",
	"technical_investigation.latest_rctime" : "เวลาล่าสุด",
	"technical_investigation.latest_ignition" : "เครื่องยนต์",
	"technical_investigation.latest_event_desc" : "Event",
	"technical_investigation.latest_gps_desc" : "สัญญาณ GPS",
	"technical_investigation.latest_gsm_desc" : "สัญญาณ GSM",
	"technical_investigation.latest_power_desc" : "ไฟเลี้ยง",
	"technical_investigation.latest_battery_desc" : "แบตเตอรี่ GPS",
	"technical_investigation.latest_io_desc": "Input/Output",
	"technical_investigation.latest_temperature": "อุณหภูมิ (c)",
	"technical_investigation.latest_exsensor_FWRV_text": "เซ็นเซอร์หมุน",
	"technical_investigation.latest_coordinate": "พิกัด",
	"technical_investigation.latest_odometer": "เลขไมล์",
	"technical_investigation.latest_fuel_percentage": "ระดับน้ำมัน (%)",
	"technical_investigation.latest_speed": "ความเร็ว",
	"technical_investigation.latest_rpm": "รอบเครื่อง",
	"technical_investigation.latest_view_on_map" : "ดูบนแผนที่",

	"ticket.title_for_technician":"การดำเนินการ",
	"ticket.title_photos":"รูปภาพการเข้าบริการ",
	"ticket.ticket_no": "Ticket No",
	"ticket.service_type": "ประเภท Ticket",
	"ticket.description" : "รายละเอียด",
	"ticket.technician": "เจ้าหน้าที่",
	"ticket.subject" : "หัวเรื่อง",
	"ticket.customer" : "ลูกค้า",
	"ticket.contact_person" : "ชื่อผู้ติดต่อ",
	"ticket.contact_person_phone" : "เบอร์ติดต่อ",
	"ticket.opened_date" : "วันที่เปิดงาน",
	"ticket.service_date" : "วันที่นัดหมาย",
	"ticket.service_description" : "รายละเอียดการเข้าบริการ",
	"ticket.remark_technician": "หมายเหตุ (สำหรับช่าง)",
	"ticket.status": "สถานะ",
	"ticket.status.All": "ทั้งหมด",
	"ticket.status.Opened": "เปิดงาน",
	"ticket.status.Checking": "กำลังตรวจสอบเบื้องต้น",
	"ticket.status.WaitITUser" : "รอตรวจสอบข้อมูลโดย IT",
	"ticket.status.WaitITSystem" : "รอตรวจสอบระบบ",
	"ticket.status.WaitAppointment" : "รอนัดหมายช่าง",
	"ticket.status.MakeAppointment": "นัดหมายช่าง",
	"ticket.status.FollowAppointment": "เข้าตามนัดหมาย",
	"ticket.status.Closed": "ปิดงาน",
	"ticket.status.Verified": "ตรวจสอบแล้ว",
	"ticket.status.Cancelled": "ยกเลิก",
	"ticket.opened_by" : "ผู้เปิด Ticket",
	"ticket.photo_request1" : "ภาพประกอบ 1",
	"ticket.photo_request2" : "ภาพประกอบ 2",
	"ticket.item_no" : "ลำดับ",
	"ticket.item_vehicle" : "ชื่อรถ",
	"ticket.item_problem" : "อาการ / ปัญหา",
	"ticket.item_cause" : "สาเหตุ",
	"ticket.item_solution" : "การแก้ไข",
	"ticket.item_price" : "ค่าบริการ",
	"ticket.item_total_price" : "รวมค่าบริการ",

	"HTK.title" : "Helpdesk Ticket",
	"HTK.title_photos":"รูปภาพประกอบ",
	"HTK.doc_no" : "Ticket No.",
	"HTK.doc_date" : "วันที่รับแจ้ง",
	"HTK.doc_time" : "เวลาที่รับแจ้ง",
	"HTK.vehicle" : "ชื่อรถ",
	"HTK.customer" : "ลูกค้า",
	"HTK.cause": "สาเหตุ",
	"HTK.cause_solution": "สาเหตุ/การแก้ไข",
	"HTK.solution": "Comment",
	"HTK.cause_sum": "สรุปสาเหตุ",
	"HTK.solution_sum": "การแก้ไข / ข้อแนะนำ",
	"HTK.description" : "รายละเอียด",
	"HTK.problem" : "ปัญหาที่พบ",
	"HTK.button.checking": "รับแจ้ง",
	"HTK.button.request": "ขอข้อมูลเพิ่มเติม",
	"HTK.button.operating": "กำลังดำเนินการ",
	"HTK.button.pending": "รอดำเนินการ",
	"HTK.button.resolved": "ปิด Ticket",
	"HTK.button.reopen": "เปิด Ticket อีกครั้ง",
	"HTK.last_updated_user_name": "ผู้อัพเดทล่าสุด",
	"HTK.approved_user_name": "ผู้ปิด Ticket",
	"HTK.created_user_name": "ผู้เปิด Ticket",
	"HTK.status_time": "อัพเดทล่าสุด",
	"HTK.ref_doc_no": "ช่องทางที่รับแจ้ง",
	"HTK.contact": "ผู้ติดต่อ",
	"HTK.contact_hint": "ชื่อลูกค้าผู้ติดต่อ",
	"HTK.staff": "ผู้ติดตาม",
	"HTK.staff_hint": "ตรวจสอบ/ประสานงาน",
	"HTK.performer": "ผู้ดำเนินการ",
	"HTK.performer_hint": "จัดทำ/แก้ไขปัญหา",

	"TRQ.title" : "Task Request",
	"TRQ.title_photos":"รูปภาพประกอบ",
	"TRQ.doc_no" : "Req No.",
	"TRQ.doc_date" : "วันที่",
	"TRQ.due_date" : "วันกำหนดเสร็จ",
	"TRQ.priority": "ความสำคัญ",
	"TRQ.progress": "ความคืบหน้า",
	"TRQ.ref_doc_no": "Ticket อ้างอิง",
	"TRQ.doc_time" : "เวลา",
	"TRQ.vehicle" : "ชื่อรถ",
	"TRQ.customer" : "ลูกค้า",
	"TRQ.solution": "การดำเนินการ",
	"TRQ.topic": "หัวข้อเรื่อง",
	"TRQ.description": "รายละเอียด",
	"TRQ.problem" : "ปัญหาที่พบ",
	"TRQ.button.checking": "รับทราบ",
	"TRQ.button.request": "ขอข้อมูลเพิ่มเติม",
	"TRQ.button.operating": "กำลังดำเนินการ",
	"TRQ.button.pending": "รอดำเนินการ",
	"TRQ.button.resolved": "ปิด Task",
	"TRQ.button.reopen": "เปิด Task อีกครั้ง",
	"TRQ.last_updated_user_name": "ผู้อัพเดทล่าสุด",
	"TRQ.approved_user_name": "ผู้ปิดงาน",
	"TRQ.created_user_name": "สร้างโดย",
	"TRQ.status_time": "อัพเดทล่าสุด",
	"TRQ.contact": "ข้อมูลผู้ติดต่อ",
	"TRQ.staff": "มอบหมายให้",

	"KNB.title" : "Information Detail",
	"KNB.title_photos":"ไฟล์แนบ",
	"KNB.doc_no" : "Doc No.",
	"KNB.doc_date" : "วันที่",
	"KNB.doc_time" : "เวลา",
	"KNB.project": "ชื่อเรื่อง",
	"KNB.group": "กลุ่ม",
	"KNB.description": "รายละเอียด",
	"KNB.button.resolved": "ยืนยันข้อมูล",
	"KNB.button.reopen": "ปรับปรุงใหม่อีกครั้ง",
	"KNB.last_updated_user_name": "ผู้อัพเดทล่าสุด",
	"KNB.approved_user_name": "ผู้ยืนยัน",
	"KNB.created_user_name": "ผู้สร้าง",
	"KNB.status_time": "อัพเดทล่าสุด",

	"TSO.title" : "Service Order",
	"TSO.title2" : "รูปภาพประกอบ",
	"TSO.title3" : "รูปภาพประกอบ (เพิ่มเติม)",
	"TSO.title_description": "รายละเอียดการเข้าเซอร์วิส",
	"TSO.title_photos":"รูปภาพประกอบ",
	"TSO.title_photos2":"รูปภาพประกอบ (เพิ่มเติม)",
	"TSO.title_install":"อุปกรณ์และการติดตั้ง",
	"TSO.title_change":"อุปกรณ์เครื่องใหม่ (กรณีเปลี่ยนเครื่อง)",
	"TSO.title_vehicle":"ข้อมูลรถ",
	"TSO.title_install_func":"ผลการติดตั้ง",
	"TSO.doc_no" : "SO No.",
	"TSO.doc_date" : "วันที่นัดหมาย",
	"TSO.doc_time" : "เริ่มงาน",
	"TSO.closed_time" : "จบงาน",
	"TSO.vehicle" : "เบอร์รถ/ทะเบียน",
	"TSO.customer" : "ลูกค้า",
	"TSO.cause": "สาเหตุ",
	"TSO.solution": "การแก้ไข",
	"TSO.cause_sum": "สรุปสาเหตุของปัญหา",
	"TSO.solution_sum": "สรุปวิธีการแก้ไข/ข้อแนะนำ",
	"TSO.place" : "สถานที่ปฏิบัติงาน",
	"TSO.place_hint": "กรุณาระบุ ชื่อไซด์งาน / สาขา / จุดติดตั้ง ของลูกค้า",
	"TSO.problem" : "ปัญหาที่พบ",
	"TSO.button.resolved": "ปิดงาน",
	"TSO.button.reopen": "ดึงงานกลับคืน",
	"TSO.created_user_name": "ผู้เปิดงาน",
	"TSO.approved_user_name": "ผู้ปิดงาน",
	"TSO.verifier": "ผู้ตรวจสอบ",
	"TSO.verifier_hint": "กรุณาระบุ ชื่อ และ ตำแหน่งงาน ของผู้ตรวจสอบ",
	"TSO.verifier_contact": "ข้อมูลติดต่อ",
	"TSO.verifier_contact_hint": "กรุณาระบุ เบอร์โทร / อีเมลล์ / Line ID ของผู้ตรวจสอบ",
	"TSO.verified_by": "ผู้ตรวจสอบและรับมอบ",
	"TSO.status_time": "อัพเดทล่าสุด",
	"TSO.contact": "ข้อมูลผู้ติดต่อ",
	"TSO.tso_type": "ประเภทงาน",
	"TSO.technician": "ผู้ปฏิบัติงาน",
	"TSO.imei": "IMEI",
	"TSO.sim": "SIM No",
	"TSO.sim_serial": "Serial",
	"TSO.brand": "ยี่ห้อ",
	"TSO.model": "รุ่น",
	"TSO.year": "ปี",
	"TSO.owner_name" : "เจ้าของรถ",
	"TSO.owner_phone" : "เบอร์โทร",
	"TSO.owner_taxno" : "เลขประจำตัวผู้เสียภาษี",
	"TSO.owner_address" : "ที่อยู่",
	"TSO.chassis_no": "เลขตัวถัง",
	"TSO.register_type": "ลักษณะรถ",
	"TSO.vehicle_role": "ประเภทการติดตั้ง",
	"TSO.vehicle_fuel": "การตั้งค่าน้ำมัน",
	"TSO.product": "อุปกรณ์",
	"TSO.vehicle_no": "เบอร์รถ",
	"TSO.regist_no2": "ทะเบียนพ่วง",
	"TSO.regist_no": "ทะเบียนรถ",
	"TSO.province": "จังหวัด",
	"TSO.fuel_capable": "ขนาดถังน้ำมัน",
	"TSO.card_reader": "เครื่องรูดบัตร",
	"TSO.mileage": "เลขไมล์",
	"TSO.description": "รายละเอียดรถ",
	"TSO.install_fuel": "วัดระดับน้ำมัน",
	"TSO.install_camera": "กล้อง",
	"TSO.install_sensor1": "PTO 1",
	"TSO.install_sensor2": "PTO 2",
	"TSO.install_sensor3": "PTO 3",
	"TSO.install_temp": "เซ็นเซอร์อุณหภูมิ",
	"TSO.install_other": "อุปกรณ์เสริมอื่นๆ",
	"TSO.vehicle_condition": "สภาพรถ",
	"TSO.warranty": "เงื่อนไขการรับประกันและการบริการ",
	"TSO.warranty_detail": "<ol style='margin:0;padding-inline-start:10px;'>"+
		"<li> อุปกรณ์รับประกัน 1 ปีภายใต้การใช้งานปกติ (ไม่ครอบคลุมอุบัติเหตุ ภัยธรรมชาติ และความเสียหายที่ไม่ได้เกิดจากความผิดพลาดของอุปกรณ์)</li>"+
		"<li> รับประกันงานซ่อม 30 วันนับจากวันที่ซ่อมเสร็จ</li>"+
		"<li> การรับประกันจะรับประกันความเสียหายของอุปกรณ์ภายใต้การใช้งานที่ถูกวิธี</li>"+
		"<li> บริษัทฯได้รับอนุญาตจากท่านให้เป็นผู้ติดตั้งอุปกรณ์ที่รถของท่าน</li>"+
		"<li> ลูกค้ากรุณาตรวจสอบสภาพรถให้เรียบร้อยครบถ้วน หากพบความบกพร่องหรือเสียหายใดกรุณาแจ้งต่อพนักงานทันที โดยบริษัทฯจะไม่รับผิดชอบต่อความบกพร่องหรือความเสียหายใดที่เกิดขึ้นหากพบปัญหาหรือความเสียดายใดหลังจาก 30 วัน นับจากวันที่ติดตั้งหรือแก้ไข</li>"+
		"<li> บริษัทฯ ขอสงวนสิทธิ์ในการคิดค่าบริการ ค่าเดินทาง ค่าใช้จ่ายในส่วนของงานซ่อม และบริการต่างๆที่อยู่นอกเหนือการรับประกัน</li>"+
		"<li> บริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขโดยไม่ต้องแจ้งให้ทราบล่วงหน้า</li>"+
		"</ol>",
	"TSO.ack" : "ข้าพเจ้ารับทราบรายละเอียดและยอมรับเงื่อนไขที่บริษัทฯกำหนด และได้ตรวจรับรถหลังการติดตั้งอุปกรณ์เรียบร้อยแล้ว รถสามารถใช้งานได้ปกติ อุปกรณ์ต่างๆครบถ้วน จึงลงชื่อไว้เป็นหลักฐาน",
	"TSO.func_gps" : "GPS ระบุตำแหน่งได้ถูกต้อง",
	"TSO.func_reader" : "เครื่องอ่านบัตรระบุผู้ขับขี่ได้ถูกต้อง",
	"TSO.func_fuel" : "แท็บสัญญาณวัดระดับน้ำมันได้",
	"TSO.signed_person1" : "เจ้าหน้าที่ปฏิบัติงาน",
	"TSO.signed_person2" : "เจ้าของรถ/ตัวแทน",
	"TSO.signed_person3" : "ตัวแทนผู้รับมอบของลูกค้า",
	"TSO.signed_person4" : "ผู้ตรวจสอบ",

	"BSO.title" : "Best Service Order",
	"BSO.title_description": "แผนงาน (กรณีนัดหมายล่วงหน้า)",
	"BSO.title_perform": "ผลการเข้าปฏิบัติงาน",
	"BSO.title_photos":"รูปภาพ",
	"BSO.photo_desc": "รูปภาพ ",
	"BSO.doc_no" : "Doc No.",
	"BSO.doc_date" : "วันที่",
	"BSO.doc_time" : "เวลา",
	"BSO.due_date" : "วันเวลาที่นัดหมาย",
	"BSO.so_type": "กิจกรรม",
	"BSO.so_type_desc": "งานอื่นๆ (ระบุ)",
	"BSO.so_type_desc_hint": "ระบุชื่อกิจกรรม",
	"BSO.contact": "ลูกค้า/ผู้ติดต่อ",
	"BSO.customer_phone": "เบอร์โทร",
	"BSO.customer_address": "ที่อยู่",
	"BSO.performer": "ผู้ปฏิบัติงาน",
	"BSO.performer_hint": "ไม่ระบุผู้ปฏิบัติงาน",
	"BSO.place": "สถานที่",
	"BSO.lat" : "ละติจูด",
	"BSO.lng" : "ลองจิจูด",
	"BSO.coord": "พิกัดตำแหน่งที่ปฏิบัติงาน",
	"BSO.created_user_name": "ผู้เปิดงาน",
	"BSO.approved_user_name": "ผู้ตรวจสอบ",
	"BSO.created_time": "เวลาที่เปิดงาน",

	"LVE.title" : "ใบลา (Leave Form)",
	"LVE.title_documents": "เอกสารแนบใบลา",
	"LVE.medical_certificate":"ใบรับรองแพทย์",
	"LVE.photo_desc":"เอกสารแนบ",
	"LVE.notice_to": "เรียน",
	"LVE.notice_to_hint": "หัวหน้างาน / ผู้บังคับบัญชา",
	"LVE.supervisor": "หัวหน้างานผู้อนุมัติ",
	"LVE.doc_no" : "Doc No.",
	"LVE.doc_date" : "วันที่แจ้ง",
	"LVE.doc_time" : "เวลา",
	"LVE.due_date": "วันที่ลา",
	"LVE.end_date": "ถึงวันที่",
	"LVE.end_date_hint": "กรณีลาติดต่อ",
	"LVE.days" : "จำนวนวันลา",
	"LVE.leave": "ขอลา",
	"LVE.leave_type": "ประเภทการลา",
	"LVE.reason": "เนื่องจาก",
	"LVE.contact": "ในระหว่างลาติดต่อข้าพเจ้าได้ที่",
	"LVE.contact_phone": "เบอร์โทรศัพท์",
	"LVE.created_user_name": "ข้าพเจ้า",
	"LVE.hr_name": "ฝ่ายบุคคล",
	"LVE.notice_by": "พนักงาน",
	"LVE.created_time": "เวลาที่แจ้ง",
	"LVE.comment1": "ความเห็นเพิ่มเติมของหัวหน้างาน",
	"LVE.comment2": "ความเห็นเพิ่มเติมของฝ่ายบุคคล",
	"LVE.status.Signed": "อนุมัติ",
	"LVE.status.Rejected": "ไม่อนุมัติ",
	"LVE.status.Closed": "ยืนยันอนุมัติ",
	"LVE.status.NotVerified": "ไม่อนุมัติ",
	"LVE.status.Cancelled": "ยกเลิก",

	"doconcrete.title": "ใบส่งสินค้า",
	"doconcrete.subtitle": "Delivery Order (Mix Concrete)",
	"doconcrete.product_info": "ราละเอียดสินค้า",
	"doconcrete.delivery_info":"การจัดส่ง",
	"doconcrete.time_info": "เวลาคาดการณ์",
	"doconcrete.instruction_info": "คำสั่งพิเศษ",
	"doconcrete.no": "เลขที่ใบส่งของ",
	"doconcrete.so_no": "เลขที่ SO",
	"doconcrete.sale_name":	"ผู้ขาย (Sale)",
	"doconcrete.doc_date": "วันที่ใบส่งของ",
	"doconcrete.produce_time": "เวลาผลิต",
	"doconcrete.estimate_time": "เวลาออก",
	"doconcrete.leave_time": "เวลาออก",
	"doconcrete.reach_time": "ถึงไซด์",
	"doconcrete.return_time": "จบงาน",
	"doconcrete.es_reach_time": "เวลาถึง",
	"doconcrete.es_dump_start_time": "เริ่มเท",
	"doconcrete.es_dump_end_time": "เทเสร็จ",
	"doconcrete.es_return_time": "ถึงแพล้นท์",
	"doconcrete.customer":	"ลูกค้า",
	"doconcrete.customer_site":	"สถานที่ส่งสินค้า",
	"doconcrete.driver":	"ผู้ขับ",
	"doconcrete.distance":	"ระยะทางโดยประมาณ",
	"doconcrete.duration":	"ระยะเวลาเดินทางโดยประมาณ",
	"doconcrete.plant":	"แพล้นท์",
	"doconcrete.truck_no": "เบอร์รถ",
	"doconcrete.contact_name": "ผู้ติดต่อหน้างาน",
	"doconcrete.contact_phone": "เบอร์โทร",
	"doconcrete.booking": "จำนวนคิว",
	"doconcrete.booking_unit": "ลบ.ม",
	"doconcrete.product_code": "Mix Code",
	"doconcrete.product_desc": "สินค้า",
	"doconcrete.cube": "Cube",
	"doconcrete.cylinder": "Cylinder",
	"doconcrete.slump": "Slump",
	"doconcrete.status.Signed": "ตรวจสภาพสินค้าเรียบร้อย",
	"doconcrete.status.Leave":"ออกจากแพล้นท์",
	"doconcrete.status.Reach":"ถึงหน้างาน",
	"doconcrete.status.DumpStart":"เริ่มเท",
	"doconcrete.status.DumpEnd":"เทเสร็จ",
	"doconcrete.status.Failed": "นำส่งไม่สำเร็จ",
	"doconcrete.status.LeaveSite":"ออกจากไซด์",
	"doconcrete.status.Complete":"จบงาน",
	"doconcrete.status.Cancelled":"ยกเลิก",

	"concrete_truck.truck_no": "เบอร์รถ",
	"concrete_truck.plant": "แพล้นท์",
	"concrete_truck.driver": "ผู้ขับ",
	"concrete_truck.driver_phone": "เบอร์โทร",
	"concrete_truck.logon_time": "Logon Time",
	"concrete_truck.queue": "ลำดับเรียกคิว",
	"concrete_truck.status_time": "เวลาที่อัพเดทสถานะ",

	"concrete_truck_check.title": "รายการตรวจสอบสภาพรถ",
	"concrete_truck_check.doc_no": "เลขที่เอกสาร",
	"concrete_truck_check.doc_date": "วันที่ตรวจเช็ค",
	"concrete_truck_check.doc_time": "เวลาที่ตรวจเช็ค",
	"concrete_truck_check.title_checklist": "รายการตรวจเช็ค",
	"concrete_truck_check.title_result": "การตรวจเช็ค",
	"concrete_truck_check.result": "ผลการตรวจเช็ค",
	"concrete_truck_check.vehicle": "เบอร์รถ",
	"concrete_truck_check.owner": "ผู้ขับรถ",
	"concrete_truck_check.photo_desc": "รูปภาพ",
	"concrete_truck_check.created_user_name": "ผู้ตรวจเช็ค",
	"concrete_truck_check.approved_user_name": "ผู้รับแจ้ง",

	"PRD.code": "รหัสสินค้า",
	"PRD.name": "ชื่อสินค้า",
	"PRD.information": "ข้อมูลสินค้า",
	"PRD.photo_main": "รูปภาพสินค้า",
	"PRD.type": "ประเภทสินค้า",
	"PRD.category": "หมวดหมู่สินค้า",
	"PRD.brand": "แบรนด์",
	"PRD.model": "รุ่น",
	"PRD.size": "ขนาด",
	"PRD.color": "สี",
	"PRD.unit": "หน่วย",
	"PRD.value": "ราคาขาย",

	"PRDentry.code": "รหัสอ้างอิง",
	"PRDentry.serial": "S/N",
	"PRDentry.code_hint": "Unique ID / IMEI / Serial No",
	"PRDentry.name": "สินค้า",
	"PRDentry.type": "ประเภทสินค้า",
	"PRDentry.brand": "แบรนด์",
	"PRDentry.model": "รุ่น",
	"PRDentry.location": "ที่เก็บปัจจุบัน",
	"PRDentry.description": "รายละเอียด",
	"PRDentry.qty": "จำนวน",
	"PRDentry.created_time": "นำเข้าสต็อก",
	"PRDentry.status_time": "สถานะล่าสุด",
	"PRDentry.status.Ready": "พร้อมขาย",
	"PRDentry.status.Checking": "ตรวจสอบ",
	"PRDentry.status.Spare": "สำรอง",
	"PRDentry.status.Claimed": "เบิกเคลม",
	"PRDentry.status.Sold": "เบิกขาย",
	"PRDentry.status.OutOfOrder": "เสีย",
	"PRDentry.status.Unknown": "ไม่ทราบสถานะ",
	"PRDentry.ref_entry": "รายการอ้างอิง",
	"PRDentry.child_codes": "SIM",

	"AST.code": "รหัสอุปกรณ์",
	"AST.name": "ชื่ออุปกรณ์",
	"AST.information": "ข้อมูลอุปกรณ์",
	"AST.photo_main": "รูปภาพอุปกรณ์",
	"AST.type": "ประเภทอุปกรณ์",
	"AST.category": "หมวดหมู่อุปกรณ์",
	"AST.brand": "แบรนด์",
	"AST.model": "รุ่น",
	"AST.size": "ขนาด",
	"AST.color": "สี",
	"AST.unit": "หน่วย",
	"AST.value": "มูลค่า",

	"ASTentry.code": "รหัสอ้างอิง",
	"ASTentry.serial": "S/N",
	"ASTentry.code_hint": "Unique ID / IMEI / Serial No",
	"ASTentry.name": "อุปกรณ์",
	"ASTentry.type": "ประเภทอุปกรณ์",
	"ASTentry.brand": "แบรนด์",
	"ASTentry.model": "รุ่น",
	"ASTentry.location": "ที่เก็บ/ถือครอง",
	"ASTentry.description": "รายละเอียด",
	"ASTentry.qty": "จำนวน",
	"ASTentry.created_time": "นำเข้าสต็อก",
	"ASTentry.status_time": "สถานะล่าสุด",
	"ASTentry.status.Ready": "พร้อมใช้งาน",
	"ASTentry.status.Checking": "ตรวจสอบ",
	"ASTentry.status.InUsed": "เบิกใช้งาน",
	"ASTentry.status.OutOfOrder": "เสีย",
	"ASTentry.status.Unknown": "ไม่ทราบสถานะ",
	"ASTentry.ref_entry": "กล่องสินค้า",

	"SPY.code": "รหัสวัสดุ",
	"SPY.name": "ชื่อวัสดุ",
	"SPY.information": "ข้อมูลวัสดุ",
	"SPY.photo_main": "รูปภาพ",
	"SPY.type": "ประเภท",
	"SPY.category": "หมวดหมู่",
	"SPY.brand": "แบรนด์",
	"SPY.model": "รุ่น",
	"SPY.size": "ขนาด",
	"SPY.color": "สี",
	"SPY.unit": "หน่วย",
	"SPY.value": "มูลค่า",

	"SPYentry.code": "รหัสอ้างอิง",
	"SPYentry.serial": "S/N",
	"SPYentry.code_hint": "รหัส Lot No / กล่อง / ชุด",
	"SPYentry.name": "วัสดุ",
	"SPYentry.type": "ประเภท",
	"SPYentry.brand": "แบรนด์",
	"SPYentry.model": "รุ่น",
	"SPYentry.location": "ที่เก็บ",
	"SPYentry.description": "รายละเอียด",
	"SPYentry.qty": "จำนวน",
	"SPYentry.created_time": "นำเข้าสต็อก",
	"SPYentry.status_time": "สถานะล่าสุด",
	"SPYentry.status.Ready": "พร้อมใช้งาน",
	"SPYentry.status.Checking": "ตรวจสอบ",
	"SPYentry.status.InUsed": "เบิกใช้งาน",
	"SPYentry.status.OutOfOrder": "เสีย",
	"SPYentry.status.Unknown": "ไม่ทราบสถานะ",
	"SPYentry.ref_entry": "รายการอ้างอิง",

	"department.title" : "แผนก",
	"department.code" : "รหัสแผนก",
	"department.text" : "ชื่อแผนก",
	"department.status.A" : "Active",
	"department.status.N" : "In-active",
	"division.title" : "หน่วยงาน",
	"division.code" : "รหัสหน่วยงาน",
	"division.text" : "ชื่อหน่วยงาน",
	"division.status.A" : "Active",
	"division.status.N" : "In-active",
	"PRD_category.title" : "หมวดหมู่สินค้า",
	"PRD_category.code" : "รหัสหมวดหมู่สินค้า",
	"PRD_category.text" : "ชื่อหมวดหมู่สินค้า",
	"PRD_category.status.A" : "Active",
	"PRD_category.status.N" : "In-active",
	"PRD_type.title" : "ประเภทสินค้า",
	"PRD_type.code" : "รหัสประเภทสินค้า",
	"PRD_type.text" : "ชื่อประเภทสินค้า",
	"PRD_type.ref_code" : "หมวดหมู่สินค้า",
	"PRD_type.status.A" : "Active",
	"PRD_type.status.N" : "In-active",
	"PRD_brand.title" : "แบรนด์สินค้า",
	"PRD_brand.code" : "รหัสแบรนด์สินค้า",
	"PRD_brand.text" : "ชื่อแบรนด์สินค้า",
	"PRD_brand.status.A" : "Active",
	"PRD_brand.status.N" : "In-active",
	"PRD_model.title" : "รุ่นสินค้า",
	"PRD_model.code" : "รหัสรุ่นสินค้า",
	"PRD_model.text" : "ชื่อรุ่นสินค้า",
	"PRD_model.ref_code" : "แบรนด์สินค้า",
	"PRD_model.status.A" : "Active",
	"PRD_model.status.N" : "In-active",
	"AST_type.title" : "ประเภทอุปกรณ์",
	"AST_type.code" : "รหัสประเภทอุปกรณ์",
	"AST_type.text" : "ชื่อประเภทอุปกรณ์",
	"AST_type.status.A" : "Active",
	"AST_type.status.N" : "In-active",
	"SPY_type.title" : "ประเภทวัสดุ",
	"SPY_type.code" : "รหัสประเภทวัสดุ",
	"SPY_type.text" : "ชื่อประเภทวัสดุ",
	"SPY_type.status.A" : "Active",
	"SPY_type.status.N" : "In-active",
	"location.title" : "คลังเก็บของ",
	"location.code" : "รหัสคลังเก็บของ",
	"location.text" : "ชื่อคลังเก็บของ",
	"location.status.A" : "Active",
	"location.status.N" : "In-active",
	"VHC_brand.title" : "แบรนด์",
	"VHC_brand.code" : "รหัสแบรนด์",
	"VHC_brand.text" : "ชื่อแบรนด์",
	"VHC_brand.status.A" : "Active",
	"VHC_brand.status.N" : "In-active",
	"knowledge_group.title" : "กลุ่ม Knowledge",
	"knowledge_group.code" : "รหัสกลุ่ม",
	"knowledge_group.text" : "ชื่อกลุ่ม",
	"knowledge_group.status.A" : "Active",
	"knowledge_group.status.N" : "In-active",
	"vehicle.title" : "รถลงทะเบียน",
	"vehicle.code" : "รหัสรถ",
	"vehicle.code_hint" : "หากติดตั้ง GPS ต้องตรงกับชื่อรถในระบบ Fleet",
	"vehicle.text" : "ชื่อรถ",
	"vehicle.text_hint" : "ชื่อที่แสดงในระบบ/แอพพลิเคชั่น",
	"vehicle.ref_code" : "ชนิดรถ",
	"vehicle.owner" : "เจ้าของรถ",
	"vehicle.gps_connected": "เชื่อมต่อ GPS",
	"vehicle.concrete_size": "ขนาดโม่ (ลบ.ม)",
	"vehicle.rplant": "แพล้นท์ที่ลงทะเบียน",
	"vehicle.pgroup": "กลุ่มรถร่วมโครงการ",
	"vehicle.yearly": "ปีการผลิต",
	"vehicle.status.A" : "Active",
	"vehicle.status.N" : "In-active",
	"project_vehicle_group.title" : "กลุ่มรถร่วมโครงการ",
	"project_vehicle_group.code" : "รหัสกลุ่มรถ",
	"project_vehicle_group.text" : "ชื่อกลุ่มรถ",
	"project_vehicle_group.status.A" : "Active",
	"project_vehicle_group.status.N" : "In-active",

	"report.query_operation" : "เรียกดูข้อมูล",
	"report.print_date" : "วันที่พิมพ์",
	"report.start_date" : "จากวันที่",
	"report.end_date" : "ถึงวันที่",
	"report.start_time" : "ตั้งแต่เวลา",
	"report.end_time" : "ถึงเวลา",
	"report.vehicle" : "เบอร์/ทะเบียนรถ",
	"report.plant" : "แพล้นท์",
	"report.site" : "ไซด์",

	"filter.start_date" : "จากวันที่",
	"filter.end_date" : "ถึงวันที่",
	"filter.customer" : "ลูกค้า",
	"filter.vehicle" : "ชื่อรถ",
	"filter.staff": "เจ้าหน้าที่",
	"filter.location": "สถานที่",

	"notify.please_relogin_to_apply_change" : "เปลี่ยนแปลงข้อมูลการตั้งค่าเรียบร้อย กรุณาLogin ใหม่อีกครั้ง เพื่อใช้งานการตั้งค่าใหม่",

	"address" : "ที่อยู่",
	"phone" : "โทรศัพท์",
	"fax" : "โทรสาร",
	"taxno" : "เลขประจำตัวผู้เสียภาษี",
	"branch" : "สาขา",
	"information" : "ข้อมูล",
	"liter" : "ลิตร",
	"km": "กม",
	"ton":"ตัน",
	"vehicle":"คัน",
	"machine":"เครื่อง",
	"rai":"ไร่",
	"before" : "ก่อน",
	"due_in" : "อีก",
	"delay" : "ล่าช้า",
	"today" : "วันนี้",
	"yesterday" : "เมื่อวานนี้",
	"day" : "วัน",
	"open_by" : "เปิดโดย",
	"sir":"คุณ",
	"all_day" : "เต็มวัน",

	"text.km" : "กม",
	"text.min" : "นาที",

	"button.verified": "ยืนยัน",
	"status.Draft": "เอกสารร่าง",
	"status.Signed": "ยืนยัน",
	"status.Cancelled": "ยกเลิก",
	"status.Opened": "เปิดงาน",
	"status.Noted": "รับงาน",
	"status.Rejected": "ไม่สามารถรับงานได้",
	"status.Worked": "เข้าปฏิบัติงาน",
	"status.CancelNoted": "ยกเลิกการรับงาน",
	"status.Failed": "ปฏิบัติงานไม่ได้",
	"status.Closed": "จบงาน",
	"status.Verified": "ตรวจสอบแล้ว",
	"status.A": "Active",
	"status.N": "In-active",
	"created_user_name" : "ผู้เปิดเอกสาร",
	"created_date" : "วันที่สร้าง",
	"last_updated_user_name" : "ผู้แก้ไขล่าสุด",
	"last_updated_date" : "วันที่แก้ไข",
	"approved_user_name" : "ผู้ยืนยัน",
	"approved_date" : "วันที่ยืนยัน"
}
