const moduleName = "survtrex"

const smarfarmorgs = [818, 920, 987, 1051, 1062, 1026, 1065, 1094, 1097, 1098, 1101]

export default {
  name : moduleName,
  api : {
    server :  "https://apiservices2.bestgeosystem.com/",   //"http://192.168.1.103:8080/",  //
    app_module : "survtrex",
  },
  title : "SURVTREX",
  description : "SURVTREX Enterprise System",
  logo : "assets/"+moduleName+"/logo.png",               // 128*128 Color Logo
  logo_head : "assets/"+moduleName+"/logo-head.png",      // 200*34 Color Logo
  theme : {
    primary: {
      light: "rgba(211,47,47,0.8)",
      main: "rgba(211,47,47,0.9)",
      dark: "rgba(211,47,47,1)",
      contrastText: '#fff'
    },
    secondary: {
      light: "rgb(255,255,255,0.9)",
      main: "rgb(255,255,255,0.97)",
      dark: "rgb(255,255,255,1)",
      contrastText: '#424242'
    }
  },
  menu:{
    main_menu: {
  		title: "sidebar.section.main_menu",
      list: [
        {"menu_title": "sidebar.dashboard", "menu_icon": "view-dashboard", allowOrg:[1130, 544], "child_routes": [
          {"menu_title": "sidebar.dashboard_concrete1","path": "/"+moduleName+"/dashboard/concrete1"},
          {"menu_title": "sidebar.dashboard_concrete_truck_available","path": "/"+moduleName+"/dashboard/concrete_truck_available"},
          {"menu_title": "sidebar.dashboard_concrete_plant_volume", allowOrg:[1130, 544] ,"path": "/"+moduleName+"/dashboard/concrete_plant_volume"},
          {"menu_title": "sidebar.dashboard_concrete_monitoring", allowOrg:[1130, 544] ,"path": "/"+moduleName+"/dashboard/concrete_monitoring"},
          {"menu_title": "sidebar.dashboard_concrete_driver_truck_performance", allowOrg:[1130, 544] ,"path": "/"+moduleName+"/dashboard/concrete_driver_truck_performance"},
          {"menu_title": "sidebar.dashboard_concrete_driver_performance", allowOrg:[1130, 544] ,"path": "/"+moduleName+"/dashboard/concrete_driver_performance"},
          {"menu_title": "sidebar.dashboard_concrete_truck_performance", allowOrg:[1130, 544] ,"path": "/"+moduleName+"/dashboard/concrete_truck_performance"},
          {"menu_title": "sidebar.dashboard_notification", allowOrg:[1130, 544],  "path": "/fms/dashboard/notification",},
        ]},
        {"menu_title": "sidebar.concrete_truck_monitoring",  "menu_icon": "truck", allowOrg:[1130, 544], "path": "/"+moduleName+"/dashboard/concrete_truck_monitoring"},
        {"menu_title": "sidebar.concrete", "menu_icon": "assignment-returned", allowOrg:[1130, 544], "child_routes": [
          {"menu_title": "sidebar.concrete_do", "path": "/"+moduleName+"/concrete_do"},
          {"menu_title": "sidebar.concrete_vehicle_check", "path": "/"+moduleName+"/concrete_vehicle_check"},
        ]},
        {"menu_title": "sidebar.service_best",  "menu_icon": "nature", "path": "/"+moduleName+"/service_best"},
        {"menu_title": "sidebar.contact", "menu_icon": "assignment", allowUserRole: ["admin","account","purchase","purchase_h","it","exec1","exec2","surveyor_admin"], "child_routes": [
          {"menu_title": "sidebar.contact_customer", "path": "/"+moduleName+"/contact/customer", denyOrg:smarfarmorgs},
          {"menu_title": "sidebar.contact_supplier", "path": "/"+moduleName+"/contact/supplier", denyOrg:smarfarmorgs},
          {"menu_title": "sidebar.contact_person", "path": "/"+moduleName+"/contact/person", denyOrg:smarfarmorgs},
          {"menu_title": "sidebar.contact_driver", "path": "/"+moduleName+"/contact/driver", denyOrg:smarfarmorgs},
          {"menu_title": "sidebar.contact_farmer", "path": "/"+moduleName+"/contact/farmer", allowUserRole: ["admin","it","exec1","exec2","surveyor_admin"], allowOrg:smarfarmorgs},
          {"menu_title": "sidebar.contact_trucker", "path": "/"+moduleName+"/contact/trucker", allowUserRole: ["admin","it"], allowOrg:smarfarmorgs},
          {"menu_title": "sidebar.contact_harvester", "path": "/"+moduleName+"/contact/harvester", allowUserRole: ["admin","it"], allowOrg:smarfarmorgs},
          {"menu_title": "sidebar.contact_cablecardriver", "path": "/"+moduleName+"/contact/cablecardriver", allowUserRole: ["admin","it"], allowOrg:smarfarmorgs},
          {"menu_title": "sidebar.contact_vendor", "path": "/"+moduleName+"/contact/vendor", allowUserRole: ["admin","it"], allowOrg:smarfarmorgs},
        ]},
        {"menu_title": "sidebar.location", "menu_icon": "pin", allowUserRole: ["admin","it"], "child_routes": [
          {"menu_title": "sidebar.location_place", "path": "/"+moduleName+"/location/place",},
          //{"menu_title": "sidebar.location_field", "path": "/"+moduleName+"/location/field",},
        ]},
        {"menu_title": "sidebar.hr", "menu_icon": "account", allowUserRole: ["admin","hr","hr_admin"], "child_routes": [
          {"menu_title": "sidebar.hr_staff", "path": "/"+moduleName+"/hr/staff",},
          {"menu_title": "sidebar.hr_department", "path": "/"+moduleName+"/hr/department",},
          {"menu_title": "sidebar.hr_division", "path": "/"+moduleName+"/hr/division",},
        ]},
        {"menu_title": "sidebar.operation", "menu_icon": "folder-person", denyOrg:smarfarmorgs,  "child_routes": [
          //{"menu_title": "sidebar.form_ot", "path": "/"+moduleName+"/form/ot",},
          {"menu_title": "sidebar.form_leave", "path": "/"+moduleName+"/form/leave",},
        ]},
        {"menu_title": "sidebar.report", "menu_icon": "file", "path": "/"+moduleName+"/report", allowUserRole:["admin","it"]},
  		]
  	},
    operation: {
      list: [
          {"menu_title": "sidebar.settings","menu_icon": "settings", allowUserRole:["admin"],"child_routes": [
            {"menu_title": "sidebar.settings_company", allowUserRole:["admin"],"path": "/"+moduleName+"/settings/company",},
            {"menu_title": "sidebar.settings_project_vehicle_group","path": "/"+moduleName+"/settings/project_vehicle_group", allowOrg:smarfarmorgs,},
            {"menu_title": "sidebar.settings_vehicle","path": "/"+moduleName+"/settings/vehicle",},
          ]},
          //{"menu_title": "sidebar.account_settings", "menu_icon": "account-o", "path": "/"+moduleName+"/account_settings"},
      ]
    }
  },
  report_menu:{
    report: {
      list: [
        {"menu_title": "sidebar.report.operation", "menu_icon": "calendar-note", "child_routes" : [
          {"menu_title": "sidebar.report.operation_bso", "path": "report/operation_bso", denyOrg:[1130, 544]},
          {"menu_title": "sidebar.report.operation_bso_checkinoutlocation", "path": "report/operation_bso_checkinoutlocation"},
        ]},
        {"menu_title": "sidebar.report.hr", "menu_icon": "calendar-note", allowUserRole:["admin","it","hr_admin","hr"], "child_routes" : [
          {"menu_title": "sidebar.report.hr_dailyworktime", "path": "report/hr_dailyworktime", denyOrg:[1130, 544]},
          {"menu_title": "sidebar.report.operation_bso_checkinoutdaily", "path": "report/operation_bso_checkinoutdaily", denyOrg:[1130, 544]},
          {"menu_title": "sidebar.report.hr_worktime_mcon", "path": "report/hr_worktime_mcon", allowOrg:[1130, 544]},
        ]},
        {"menu_title": "sidebar.report.notification_event", "menu_icon": "notifications", allowOrg:[1130, 544], "child_routes" : [
          {"menu_title": "sidebar.report.notification_action", "path": "report/notification_action"},
        ]},
        {"menu_title": "sidebar.report.concrete", "menu_icon": "truck-fast", "icontype":"MaterialCommunityIcons", allowOrg:[1130, 544], "child_routes" : [
          {"menu_title": "sidebar.report.concrete_truck_daily","path": "report/concrete_truck_daily"},
          {"menu_title": "sidebar.report.concrete_stafftruck_daily","path": "report/concrete_stafftruck_daily"},
          {"menu_title": "sidebar.report.concrete_work_by_do_time", "path": "report/concrete_workbydotime"},
          {"menu_title": "sidebar.report.concrete_workdodistance","path": "report/concrete_workdodistance"},
          {"menu_title": "sidebar.report.concrete_truck_repair", "path": "report/concrete_truck_repair"},
        ]},
      ]
    }
  },
}
