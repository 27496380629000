const moduleName = "erp"

export default {
  name : moduleName,
  api : {
    server : "http://192.168.1.103:8082/",  //"https://sandbox.magbizcloud.com/",  
    app_module : moduleName,
  },
  title : "Magbiz Cloud",
  description : "Management solution for smart business",
  logo : "assets/"+moduleName+"/logo.png",                // 128*128 Color Logo
  logo_head : "assets/"+moduleName+"/logo-head.png",      // 200*34 Color Logo
  theme : {
    primary: {
      //light: "#2ECC71",
      //main: "#229954",
      //dark: "#1E8449",
      light: "hsl(200, 100%, 65%)",
      main: "hsl(200, 100%, 50%)",
      dark: "hsl(200, 100%, 48%)",
      contrastText: '#fff'
    },
    secondary: {
      light: "rgb(255,255,255,0.9)",
      main: "rgb(255,255,255,0.95)",
      dark: "rgb(255,255,255,1)",
      contrastText: '#424242'
    }
  },
  menu:{
    main_menu: {
  		title: "sidebar.section.main_menu",
  		list: [
  			{"menu_title": "sidebar.dashboard", "menu_icon": "view-dashboard", "path": "/"+moduleName+"/dashboard", allowUserRole: ["admin"]},
        {"menu_title": "sidebar.contact", "menu_icon": "assignment", allowUserRole: ["admin","account","purchase","purchase_h"], "child_routes": [
          {"menu_title": "sidebar.contact_customer", "path": "/"+moduleName+"/contact/customer",},
          {"menu_title": "sidebar.contact_supplier", "path": "/"+moduleName+"/contact/supplier",},
          {"menu_title": "sidebar.contact_person", "path": "/"+moduleName+"/contact/person",},
        ]},
        {"menu_title": "sidebar.pm", "menu_icon": "assignment", allowUserRole: ["admin","pm"], "child_routes": [
          {"menu_title": "sidebar.pm_project", "path": "/"+moduleName+"/pm/project"},
          {"menu_title": "sidebar.pm_assignment", "path": "/"+moduleName+"/pm/assignment",},
          {"menu_title": "sidebar.pm_do", "path": "/"+moduleName+"/pm/do",},
        ]},
        {"menu_title": "sidebar.sale", "menu_icon": "money-box", allowUserRole: ["admin","sale","account"], "child_routes": [
          {"menu_title": "sidebar.sale_qt", "path": "/"+moduleName+"/sale/qt",},
          {"menu_title": "sidebar.sale_so", "path": "/"+moduleName+"/sale/so",},
          {"menu_title": "sidebar.account_invoice", "path": "/"+moduleName+"/account/invoice", allowUserRole: ["admin","account"]},
          {"menu_title": "sidebar.account_receipt", "path": "/"+moduleName+"/account/receipt", allowUserRole: ["admin","account"]},
        ]},
        {"menu_title": "sidebar.purchase", "menu_icon": "assignment-returned", allowUserRole: ["admin","purchase","purchase_h"], "child_routes": [
          {"menu_title": "sidebar.purchase_po", "path": "/"+moduleName+"/purchase/po",},
        ]},
        {"menu_title": "sidebar.hr", "menu_icon": "male-female", allowUserRole: ["admin","hr","hr_h"], "child_routes": [
          {"menu_title": "sidebar.hr_staff", "path": "/"+moduleName+"/hr/staff",},
          {"menu_title": "sidebar.hr_timesheet", "path": "/"+moduleName+"/hr/timesheet",},
          {"menu_title": "sidebar.hr_payroll", "path": "/"+moduleName+"/hr/payroll",},
          {"menu_title": "sidebar.hr_department", "path": "/"+moduleName+"/hr/department", allowUserRole: ["admin","hr_h"]},
          {"menu_title": "sidebar.hr_division", "path": "/"+moduleName+"/hr/division", allowUserRole: ["admin","hr_h"]},
        ]},
        {"menu_title": "sidebar.account", "menu_icon": "balance-wallet", allowUserRole: ["admin","account","account_h"], "child_routes": [

        ]},
        {"menu_title": "sidebar.warehouse", "menu_icon": "store", allowUserRole: ["admin","stock","stock_h"], "child_routes": [
          {"menu_title": "sidebar.warehouse_PRD_stock", "path": "/"+moduleName+"/warehouse/PRD_stock",},
          {"menu_title": "sidebar.warehouse_PRD", "path": "/"+moduleName+"/warehouse/PRD", allowUserRole: ["admin","stock_h"]},
          {"menu_title": "sidebar.warehouse_PRD_category", "path": "/"+moduleName+"/warehouse/PRD_category", allowUserRole: ["admin","stock_h"]},
          {"menu_title": "sidebar.warehouse_PRD_type", "path": "/"+moduleName+"/warehouse/PRD_type", allowUserRole: ["admin","stock_h"]},
          {"menu_title": "sidebar.warehouse_PRD_brand", "path": "/"+moduleName+"/warehouse/PRD_brand", allowUserRole: ["admin","stock_h"]},
          {"menu_title": "sidebar.warehouse_PRD_model", "path": "/"+moduleName+"/warehouse/PRD_model", allowUserRole: ["admin","stock_h"]},
        ]},
        {"menu_title": "sidebar.warehouse_asm_supplies", "menu_icon": "store-24", allowUserRole: ["admin","stock","stock_h"], "child_routes": [
          {"menu_title": "sidebar.warehouse_AST_stock", "path": "/"+moduleName+"/warehouse/AST_stock",},
          {"menu_title": "sidebar.warehouse_SPY_stock", "path": "/"+moduleName+"/warehouse/SPY_stock",},
          {"menu_title": "sidebar.warehouse_AST", "path": "/"+moduleName+"/warehouse/AST", allowUserRole: ["admin","stock_h"]},
          {"menu_title": "sidebar.warehouse_SPY", "path": "/"+moduleName+"/warehouse/SPY", allowUserRole: ["admin","stock_h"]},
          {"menu_title": "sidebar.warehouse_AST_category", "path": "/"+moduleName+"/warehouse/AST_category", allowUserRole: ["admin","stock_h"]},
          {"menu_title": "sidebar.warehouse_SPY_category", "path": "/"+moduleName+"/warehouse/SPY_category", allowUserRole: ["admin","stock_h"]},
          {"menu_title": "sidebar.warehouse_AST_type", "path": "/"+moduleName+"/warehouse/AST_type", allowUserRole: ["admin","stock_h"]},
          {"menu_title": "sidebar.warehouse_SPY_type", "path": "/"+moduleName+"/warehouse/SPY_type", allowUserRole: ["admin","stock_h"]},
        ]},
        {"menu_title": "sidebar.operation", "menu_icon": "folder-person",  "child_routes": [
          {"menu_title": "sidebar.operation_pr", "path": "/"+moduleName+"/operation/pr",},
          {"menu_title": "sidebar.operation_eqr", "path": "/"+moduleName+"/operation/eqr",},
          {"menu_title": "sidebar.operation_pdr", "path": "/"+moduleName+"/operation/pdr",},
          {"menu_title": "sidebar.operation_ot", "path": "/"+moduleName+"/operation/ot",},
        ]},
        /*{"menu_title": "sidebar.services", "menu_icon": "settings-square", allowUserRole: ["admin","technician"], "child_routes": [
          {"menu_title": "sidebar.services_air", "path": "/"+moduleName+"/services/air",},
          {"menu_title": "sidebar.services_aircalendar", "path": "/"+moduleName+"/services/aircalendar",},
        ]},*/
  		]
  	},
    operation: {
      list: [
          {"menu_title": "sidebar.company_settings","menu_icon": "settings", allowUserRole:["admin"],"child_routes": [
            {"menu_title": "sidebar.settings_company","path": "/"+moduleName+"/settings/company",},
            {"menu_title": "sidebar.settings_location","path": "/"+moduleName+"/settings/location",},
    			]},
          {"menu_title": "sidebar.account_settings", "menu_icon": "account-o", "path": "/"+moduleName+"/account_settings"},
      ]
    }
  },
  report_menu:{
    report: {
      list: [
        {"menu_title": "sidebar.report.detail", "menu_icon": "info-outline", "child_routes" : [
            {"menu_title": "sidebar.report.report1","path": "report/report1"},
            {"menu_title": "sidebar.report.report2","path": "report/report2"},
        ]}
      ]
    }
  }
}
